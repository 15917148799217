import axios from 'axios'

const getDefaultState = () => ({
  funktionen: [],
  funktion: {},
})

const state = getDefaultState()

const getters = {
  funktionen: state => state.funktionen,
  funktion: state => state.funktion,
}

const mutations = {
  SET_ALL: (state, funktionen) => (state.funktionen = funktionen),

  SET_ONE: (state, funktion) => (state.funktion = funktion),

  SET_POSITION: (state, { id, position }) => {
    const index = state.funktionen.findIndex(funktion => funktion.id == id)
    const removed = state.funktionen.splice(index, 1)[0]
    state.funktionen.splice(position - 1, 0, removed)
    let order = 1
    state.funktionen.forEach(funktion => (funktion.order = order++))
  },
}

const actions = {
  getOne: async ({ commit, rootGetters }, id) => {
    const lang = rootGetters.lang
    const url = `${lang}/rechtsanwalt-funktionen/${id}`
    const res = await axios.get(url)
    commit('SET_ONE', res.data.data)
    return res.data.data
  },

  create: async ({ dispatch, rootGetters }, formData) => {
    try {
      const lang = rootGetters.lang
      const url = `${lang}/rechtsanwalt-funktionen`
      const res = await axios.post(url, formData)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Funktion angelegt',
          mode: 'good',
        },
        { root: true }
      )
      return res.data.data
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Funktion-Anlegen fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  update: async ({ commit, dispatch, rootGetters }, { id, formData }) => {
    try {
      const lang = rootGetters.lang
      const url = `${lang}/rechtsanwalt-funktionen/${id}`
      const res = await axios.patch(url, formData)
      commit('SET_ONE', res.data.data)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Funktion aktualisiert',
          mode: 'good',
        },
        { root: true }
      )
      return res.data.data
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Funktion-Aktualisieren fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  delete: async ({ dispatch, rootGetters }, id) => {
    try {
      const lang = rootGetters.lang
      const url = `${lang}/rechtsanwalt-funktionen/${id}`
      const res = await axios.delete(url)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Funktion gelöscht',
          mode: 'good',
        },
        { root: true }
      )
      return res.data.data
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Funktion-Löschen fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  setPosition: async ({ commit, dispatch, rootGetters }, payload) => {
    commit('SET_POSITION', payload)
    try {
      const lang = rootGetters.lang
      const url = `${lang}/rechtsanwalt-funktionen/${payload.id}/set-position`
      const data = { position: payload.position }
      const res = await axios.post(url, data)
      return res.data.data
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Reihenfolge konnte nicht geändert werden - Bitte Seite aktualisieren',
          mode: 'bad',
          duration: 10000,
        },
        { root: true }
      )
      throw err.response.data
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
