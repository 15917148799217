<template>
  <div>
    <GoBack :to="{ name: 'FachgebieteShow', params: { fachgebietId } }">
      Fachgebiet: &bdquo;{{ fachgebiet.title }}&ldquo;
    </GoBack>

    <h1>Fachgebiet bearbeiten</h1>

    <VForm @submit="submit">
      <FormBoxFields>
        <FormFieldInput
          field="title"
          label="Fachgebiet*"
          fieldWidth="32rem"
          v-model="formData.title"
          :errors="formErrors"
          :rules="validationRules"
          :af="true"
        />

        <FormFieldTextarea
          field="introduction"
          label="Einleitung"
          fieldWidth="32rem"
          :rows="8"
          v-model="formData.introduction"
          :errors="formErrors"
          :rules="validationRules"
        />

        <FormRowFields>
          <FormLabelInline for="bild">
            Bild
            <FormInfo title="Bild">
              Wenn Sie ein Bild auswählen, wird das bisherige Bild durch das ausgewählte Bild
              ersetzt. Bitte stellen Sie sicher, dass das Bild im sRGB-Farbprofil abgespeichert ist,
              um unerwünschte Farbveränderungen zu vermeiden. Maximal 10 MB.
            </FormInfo>
          </FormLabelInline>
          <input type="file" name="bild" ref="bild" />
        </FormRowFields>
      </FormBoxFields>

      <FormRowButtons>
        <VButton type="submit" :deactivated="submitted">SPEICHERN</VButton>
        <VButton
          type="link"
          :to="{ name: 'FachgebieteShow', params: { fachgebietId } }"
          mode="faint"
        >
          ABBRECHEN
        </VButton>
      </FormRowButtons>
    </VForm>

    <VModal ref="imageOptimizationsModal">
      <template slot="title">Bitte warten...</template>
      <p>
        Das Bild wird für unterschiedliche Bildschirmgrößen optimiert. Dieser Prozess kann mehrere
        Minuten dauern.
      </p>
    </VModal>
  </div>
</template>

<script>
import store from '@/store'
import formValidation from '@/assets/js/formValidation'
import validationRules from '@/assets/js/validationRules/fachgebiete'

import GoBack from '@/components/global/GoBack'
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/global/Button'
import VModal from '@/components/global/Modal'

export default {
  name: 'FachgebieteEdit',
  components: {
    GoBack,
    ...FormComponents,
    VButton,
    VModal,
  },
  props: ['fachgebietId'],
  data() {
    return {
      formData: null,
      formErrors: [],
      validationRules,
      submitted: false,
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('fachgebiete/getOne', to.params.fachgebietId)
    next()
  },
  created() {
    this.formData = {
      title: this.fachgebiet.title ?? '',
      introduction: this.fachgebiet.introduction ?? '',
    }
  },
  computed: {
    fachgebiet: () => store.getters['fachgebiete/fachgebiet'],
  },
  methods: {
    ...formValidation,
    async submit() {
      this.validateAll(this.formData, this.formErrors, this.validationRules)
      if (this.formErrors.length > 0) return

      this.submitted = true
      let formData = new FormData()
      Object.entries(this.formData).forEach(([field, value]) => {
        formData.append(field, value)
      })
      if (this.$refs.bild.files?.[0]) {
        formData.append('bild', this.$refs.bild.files[0])
        this.$refs.imageOptimizationsModal.open()
      }

      await this.$store.dispatch('fachgebiete/update', {
        id: this.fachgebietId,
        formData,
      })
      this.$router.push({
        name: 'FachgebieteShow',
        params: { fachgebietId: this.fachgebietId },
      })
    },
  },
}
</script>
