import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import Auth from '../layouts/Auth.vue'
import Guest from '../layouts/Guest.vue'

import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'

import HomeIndex from '../views/Home/Index.vue'
import HomeEdit from '../views/Home/Edit.vue'

import KanzleiIndex from '../views/Kanzlei/Index.vue'
import KanzleiEdit from '../views/Kanzlei/Edit.vue'

import FachgebieteIndex from '../views/Fachgebiete/Index.vue'
import FachgebieteCreate from '../views/Fachgebiete/Create.vue'
import FachgebieteShow from '../views/Fachgebiete/Show.vue'
import FachgebieteEdit from '../views/Fachgebiete/Edit.vue'

import FachgebietKernkompetenzenCreate from '../views/FachgebietKernkompetenzen/Create.vue'
import FachgebietKernkompetenzenEdit from '../views/FachgebietKernkompetenzen/Edit.vue'

import TeamIndex from '../views/Team/Index.vue'

import RechtsanwaelteCreate from '../views/Rechtsanwaelte/Create.vue'
import RechtsanwaelteShow from '../views/Rechtsanwaelte/Show.vue'
import RechtsanwaelteEdit from '../views/Rechtsanwaelte/Edit.vue'

import RechtsanwaltSchwerpunkteCreate from '../views/RechtsanwaltSchwerpunkte/Create.vue'
import RechtsanwaltSchwerpunkteEdit from '../views/RechtsanwaltSchwerpunkte/Edit.vue'

import RechtsanwaltAusbildungenCreate from '../views/RechtsanwaltAusbildungen/Create.vue'
import RechtsanwaltAusbildungenEdit from '../views/RechtsanwaltAusbildungen/Edit.vue'

import RechtsanwaltBerufserfahrungenCreate from '../views/RechtsanwaltBerufserfahrungen/Create.vue'
import RechtsanwaltBerufserfahrungenEdit from '../views/RechtsanwaltBerufserfahrungen/Edit.vue'

import RechtsanwaltFunktionenCreate from '../views/RechtsanwaltFunktionen/Create.vue'
import RechtsanwaltFunktionenEdit from '../views/RechtsanwaltFunktionen/Edit.vue'

import RechtsanwaltPublikationenCreate from '../views/RechtsanwaltPublikationen/Create.vue'
import RechtsanwaltPublikationenEdit from '../views/RechtsanwaltPublikationen/Edit.vue'

import TeammitgliederCreate from '../views/Teammitglieder/Create.vue'
import TeammitgliederShow from '../views/Teammitglieder/Show.vue'
import TeammitgliederEdit from '../views/Teammitglieder/Edit.vue'

import HonorarIndex from '../views/Honorar/Index.vue'
import HonorarEdit from '../views/Honorar/Edit.vue'

import DatenschutzIndex from '../views/Datenschutz/Index.vue'
import DatenschutzEdit from '../views/Datenschutz/Edit.vue'

import PasswortAendern from '../views/PasswortAendern.vue'

import PageNotFound from '../views/PageNotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Auth,
    meta: { requiresAuth: true },
    children: [
      {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
      },
      {
        path: '/home',
        name: 'HomeIndex',
        component: HomeIndex,
      },
      {
        path: '/home/bearbeiten',
        name: 'HomeEdit',
        component: HomeEdit,
      },
      {
        path: '/kanzlei',
        name: 'KanzleiIndex',
        component: KanzleiIndex,
      },
      {
        path: '/kanzlei/bearbeiten',
        name: 'KanzleiEdit',
        component: KanzleiEdit,
      },
      {
        path: '/fachgebiete',
        name: 'FachgebieteIndex',
        component: FachgebieteIndex,
      },
      {
        path: '/fachgebiete/neu',
        name: 'FachgebieteCreate',
        component: FachgebieteCreate,
      },
      {
        path: '/fachgebiete/:fachgebietId',
        name: 'FachgebieteShow',
        component: FachgebieteShow,
        props: true,
      },
      {
        path: '/fachgebiete/:fachgebietId/bearbeiten',
        name: 'FachgebieteEdit',
        component: FachgebieteEdit,
        props: true,
      },
      {
        path: '/fachgebiete/kernkompetenzen/neu',
        name: 'FachgebietKernkompetenzenCreate',
        component: FachgebietKernkompetenzenCreate,
        props: route => ({ fachgebietId: route.query.fachgebietId }),
      },
      {
        path: '/fachgebiete/kernkompetenzen/:kernkompetenzId/bearbeiten',
        name: 'FachgebietKernkompetenzenEdit',
        component: FachgebietKernkompetenzenEdit,
        props: true,
      },
      {
        path: '/team',
        name: 'TeamIndex',
        component: TeamIndex,
      },
      {
        path: '/team/rechtsanwaelte/neu',
        name: 'RechtsanwaelteCreate',
        component: RechtsanwaelteCreate,
      },
      {
        path: '/team/rechtsanwaelte/:rechtsanwaltId',
        name: 'RechtsanwaelteShow',
        component: RechtsanwaelteShow,
        props: true,
      },
      {
        path: '/team/rechtsanwaelte/:rechtsanwaltId/bearbeiten',
        name: 'RechtsanwaelteEdit',
        component: RechtsanwaelteEdit,
        props: true,
      },
      {
        path: '/team/rechtsanwaelte/schwerpunkte/neu',
        name: 'RechtsanwaltSchwerpunkteCreate',
        component: RechtsanwaltSchwerpunkteCreate,
        props: route => ({ rechtsanwaltId: route.query.rechtsanwaltId }),
      },
      {
        path: '/team/rechtsanwaelte/schwerpunkte/:schwerpunktId/bearbeiten',
        name: 'RechtsanwaltSchwerpunkteEdit',
        component: RechtsanwaltSchwerpunkteEdit,
        props: true,
      },
      {
        path: '/team/rechtsanwaelte/ausbildungen/neu',
        name: 'RechtsanwaltAusbildungenCreate',
        component: RechtsanwaltAusbildungenCreate,
        props: route => ({ rechtsanwaltId: route.query.rechtsanwaltId }),
      },
      {
        path: '/team/rechtsanwaelte/ausbildungen/:ausbildungId/bearbeiten',
        name: 'RechtsanwaltAusbildungenEdit',
        component: RechtsanwaltAusbildungenEdit,
        props: true,
      },
      {
        path: '/team/rechtsanwaelte/berufserfahrungen/neu',
        name: 'RechtsanwaltBerufserfahrungenCreate',
        component: RechtsanwaltBerufserfahrungenCreate,
        props: route => ({ rechtsanwaltId: route.query.rechtsanwaltId }),
      },
      {
        path: '/team/rechtsanwaelte/berufserfahrungen/:berufserfahrungId/bearbeiten',
        name: 'RechtsanwaltBerufserfahrungenEdit',
        component: RechtsanwaltBerufserfahrungenEdit,
        props: true,
      },
      {
        path: '/team/rechtsanwaelte/funktionen/neu',
        name: 'RechtsanwaltFunktionenCreate',
        component: RechtsanwaltFunktionenCreate,
        props: route => ({ rechtsanwaltId: route.query.rechtsanwaltId }),
      },
      {
        path: '/team/rechtsanwaelte/funktionen/:funktionId/bearbeiten',
        name: 'RechtsanwaltFunktionenEdit',
        component: RechtsanwaltFunktionenEdit,
        props: true,
      },
      {
        path: '/team/rechtsanwaelte/publikationen/neu',
        name: 'RechtsanwaltPublikationenCreate',
        component: RechtsanwaltPublikationenCreate,
        props: route => ({ rechtsanwaltId: route.query.rechtsanwaltId }),
      },
      {
        path: '/team/rechtsanwaelte/publikationen/:publikationId/bearbeiten',
        name: 'RechtsanwaltPublikationenEdit',
        component: RechtsanwaltPublikationenEdit,
        props: true,
      },
      {
        path: '/team/teammitglieder/neu',
        name: 'TeammitgliederCreate',
        component: TeammitgliederCreate,
      },
      {
        path: '/team/teammitglieder/:teammitgliedId',
        name: 'TeammitgliederShow',
        component: TeammitgliederShow,
        props: true,
      },
      {
        path: '/team/teammitglieder/:teammitgliedId/bearbeiten',
        name: 'TeammitgliederEdit',
        component: TeammitgliederEdit,
        props: true,
      },
      {
        path: '/honorar',
        name: 'HonorarIndex',
        component: HonorarIndex,
      },
      {
        path: '/honorar/bearbeiten',
        name: 'HonorarEdit',
        component: HonorarEdit,
      },
      {
        path: '/datenschutz',
        name: 'DatenschutzIndex',
        component: DatenschutzIndex,
      },
      {
        path: '/datenschutz/bearbeiten',
        name: 'DatenschutzEdit',
        component: DatenschutzEdit,
      },
      {
        path: '/passwort-aendern',
        name: 'PasswortAendern',
        component: PasswortAendern,
      },
    ],
  },
  {
    path: '/',
    component: Guest,
    meta: { requiresGuest: true },
    children: [
      {
        path: '/login',
        name: 'Login',
        component: Login,
      },
    ],
  },
  {
    path: '*',
    component: Auth,
    children: [
      {
        path: '',
        name: '404',
        component: PageNotFound,
        beforeEnter: (to, from, next) => {
          if (store.getters['auth/isAuth']) next()
          else next({ name: 'Login' })
        },
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  await store.dispatch('auth/getUser')
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['auth/isAuth']) next({ name: 'Login' })
    else next()
  } else if (to.matched.some(record => record.meta.requiresGuest)) {
    if (store.getters['auth/isAuth']) next({ name: 'Dashboard' })
    else next()
  } else {
    next()
  }
  next()
})

export default router
