import axios from 'axios'

const getDefaultState = () => ({
  ausbildungen: [],
  ausbildung: {},
})

const state = getDefaultState()

const getters = {
  ausbildungen: state => state.ausbildungen,
  ausbildung: state => state.ausbildung,
}

const mutations = {
  SET_ALL: (state, ausbildungen) => (state.ausbildungen = ausbildungen),

  SET_ONE: (state, ausbildung) => (state.ausbildung = ausbildung),

  SET_POSITION: (state, { id, position }) => {
    const index = state.ausbildungen.findIndex(ausbildung => ausbildung.id == id)
    const removed = state.ausbildungen.splice(index, 1)[0]
    state.ausbildungen.splice(position - 1, 0, removed)
    let order = 1
    state.ausbildungen.forEach(ausbildung => (ausbildung.order = order++))
  },
}

const actions = {
  getOne: async ({ commit, rootGetters }, id) => {
    const lang = rootGetters.lang
    const url = `${lang}/rechtsanwalt-ausbildungen/${id}`
    const res = await axios.get(url)
    commit('SET_ONE', res.data.data)
    return res.data.data
  },

  create: async ({ dispatch, rootGetters }, formData) => {
    try {
      const lang = rootGetters.lang
      const url = `${lang}/rechtsanwalt-ausbildungen`
      const res = await axios.post(url, formData)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Ausbildung angelegt',
          mode: 'good',
        },
        { root: true }
      )
      return res.data.data
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Ausbildung-Anlegen fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  update: async ({ commit, dispatch, rootGetters }, { id, formData }) => {
    try {
      const lang = rootGetters.lang
      const url = `${lang}/rechtsanwalt-ausbildungen/${id}`
      const res = await axios.patch(url, formData)
      commit('SET_ONE', res.data.data)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Ausbildung aktualisiert',
          mode: 'good',
        },
        { root: true }
      )
      return res.data.data
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Ausbildung-Aktualisieren fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  delete: async ({ dispatch, rootGetters }, id) => {
    try {
      const lang = rootGetters.lang
      const url = `${lang}/rechtsanwalt-ausbildungen/${id}`
      const res = await axios.delete(url)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Ausbildung gelöscht',
          mode: 'good',
        },
        { root: true }
      )
      return res.data.data
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Ausbildung-Löschen fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  setPosition: async ({ commit, dispatch, rootGetters }, payload) => {
    commit('SET_POSITION', payload)
    try {
      const lang = rootGetters.lang
      const url = `${lang}/rechtsanwalt-ausbildungen/${payload.id}/set-position`
      const data = { position: payload.position }
      const res = await axios.post(url, data)
      return res.data.data
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Reihenfolge konnte nicht geändert werden - Bitte Seite aktualisieren',
          mode: 'bad',
          duration: 10000,
        },
        { root: true }
      )
      throw err.response.data
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
