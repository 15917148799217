<template>
  <div>
    <h1>Honorar</h1>

    <content-box class="contentBox" v-html="honorar.article" maxWidth="40rem"></content-box>

    <VSpacer mode="small" />

    <VButton type="link" :to="{ name: 'HonorarEdit' }">
      BEARBEITEN
    </VButton>

    <VSpacer />

    <VToggle name="published" :value="honorar.published" @click="togglePublished">
      Seite anzeigen
      <FormInfo title="Seite anzeigen">
        Soll auf der Website eine Honorarseite in dieser Sprache angezeigt werden?
      </FormInfo>
    </VToggle>
  </div>
</template>

<script>
import store from '@/store'

import ContentBox from '@/components/global/ContentBox'
import VButton from '@/components/global/Button'
import VSpacer from '@/components/global/Spacer'
import VToggle from '@/components/global/Toggle'
import FormInfo from '@/components/FormComponents/FormInfo'

export default {
  name: 'HonorarIndex',
  components: {
    ContentBox,
    VButton,
    VSpacer,
    VToggle,
    FormInfo,
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('honorar/get')
    next()
  },
  computed: {
    honorar: () => store.getters['honorar/honorar'],
  },
  methods: {
    togglePublished(newValue) {
      if (newValue) {
        this.$store.dispatch('honorar/publish')
      } else {
        this.$store.dispatch('honorar/unpublish')
      }
    },
  },
}
</script>
