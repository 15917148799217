<template>
  <div>
    <GoBack :to="{ name: 'FachgebieteIndex' }">Fachgebiete</GoBack>

    <h1>Fachgebiet: &bdquo;{{ fachgebiet.title }}&ldquo;</h1>

    <div v-if="fachgebiet.bild" class="img">
      <ResponsiveImage :urls="fachgebiet.bild" sizes="32rem" alt="Bild" />
    </div>
    <div v-else>(Kein Bild hochgeladen)</div>

    <VSpacer mode="small" />

    <DetailsTable>
      <tbody>
        <tr>
          <th>Fachgebiet</th>
          <td>{{ fachgebiet.title }}</td>
        </tr>
        <tr>
          <th>Einleitung</th>
          <td>{{ fachgebiet.introduction ? trimText(fachgebiet.introduction, 512) : '' }}</td>
        </tr>
      </tbody>
    </DetailsTable>

    <VSpacer mode="small" />

    <VButton type="link" :to="{ name: 'FachgebieteEdit', params: { fachgebietId } }">
      BEARBEITEN
    </VButton>
    <VButton class="delete-button" type="button" mode="danger" @click="$refs.deleteModal.open()">
      LÖSCHEN
    </VButton>

    <VSpacer />

    <VToggle name="hasDetailsPage" :value="fachgebiet.hasDetailsPage" @click="toggleHasDetailsPage">
      Seite anzeigen
      <FormInfo title="Seite anzeigen">
        Soll auf der Website eine eigene Seite für dieses Fachgebiet (in dieser Sprache) angezeigt
        werden?
      </FormInfo>
    </VToggle>

    <VSpacer />

    <h2>Kernkompetenzen</h2>

    <VSpacer mode="small" />

    <ListTable v-if="kernkompetenzen">
      <thead v-if="kernkompetenzen.length > 0">
        <tr>
          <th>Kernkompetenz</th>
          <th><OrderIcon class="table-icon" /></th>
        </tr>
      </thead>
      <TbodyDraggable @end="onOrderEnd($event)">
        <tr v-for="({ id, kompetenz }, i) in kernkompetenzen" :key="id">
          <TdLink :to="{ name: 'FachgebietKernkompetenzenEdit', params: { kernkompetenzId: id } }">
            {{ trimText(kompetenz, 48) }}
          </TdLink>
          <td>
            <TableOrder :order="i + 1" @submit="setOrder({ id, position: $event })" />
          </td>
        </tr>
        <tr>
          <TableAddItem
            :to="{ name: 'FachgebietKernkompetenzenCreate', query: { fachgebietId } }"
            :colspan="2"
          >
            Kernkompetenz hinzufügen
          </TableAddItem>
        </tr>
      </TbodyDraggable>
    </ListTable>

    <ModalConfirm ref="deleteModal" @confirm="deleteFachgebiet">
      Sind Sie sicher, dass Sie dieses Fachgebiet löschen wollen?
    </ModalConfirm>
  </div>
</template>

<script>
import store from '@/store'

import trimText from '@/assets/js/trimText'

import GoBack from '@/components/global/GoBack'
import TableComponents from '@/components/TableComponents'
import VButton from '@/components/global/Button'
import VSpacer from '@/components/global/Spacer'
import ResponsiveImage from '@/components/ResponsiveImage'
import VToggle from '@/components/global/Toggle'
import ModalConfirm from '@/components/global/ModalConfirm'
import FormInfo from '@/components/FormComponents/FormInfo'

import OrderIcon from '@/components/cmsIcons/OrderIcon'

export default {
  name: 'FachgebieteShow',
  components: {
    GoBack,
    ...TableComponents,
    VButton,
    VSpacer,
    ResponsiveImage,
    VToggle,
    ModalConfirm,
    FormInfo,
    OrderIcon,
  },
  props: ['fachgebietId'],
  async beforeRouteEnter(to, from, next) {
    const fachgebiet = await store.dispatch('fachgebiete/getOne', to.params.fachgebietId)
    store.commit('fachgebietKernkompetenzen/SET_ALL', fachgebiet.kernkompetenzen)
    next()
  },
  computed: {
    fachgebiet: () => store.getters['fachgebiete/fachgebiet'],
    kernkompetenzen: () => store.getters['fachgebietKernkompetenzen/kernkompetenzen'],
  },
  methods: {
    trimText,
    toggleHasDetailsPage(newValue) {
      if (newValue) {
        this.$store.dispatch('fachgebiete/activateDetailsPage', this.fachgebietId)
      } else {
        this.$store.dispatch('fachgebiete/deactivateDetailsPage', this.fachgebietId)
      }
    },
    setOrder(payload) {
      this.$store.dispatch('fachgebietKernkompetenzen/setPosition', payload)
    },
    onOrderEnd(event) {
      const id = this.kernkompetenzen[event.oldIndex].id
      const position = event.newIndex + 1
      this.$store.dispatch('fachgebietKernkompetenzen/setPosition', { id, position })
    },
    async deleteFachgebiet() {
      await this.$store.dispatch('fachgebiete/delete', this.fachgebietId)
      this.$router.push({ name: 'FachgebieteIndex' })
    },
  },
}
</script>

<style lang="scss" scoped>
.table-icon {
  height: 2rem;
  fill: $col-white;
}

.delete-button {
  margin-left: 1rem;
}

.img {
  width: 32rem;
}
</style>
