export default {
  title: {
    required: {
      message: {
        de: 'Bitte geben Sie ein Fachgebiet an.',
      },
    },
    max: {
      characters: 64,
      message: {
        de: 'Das Fachgebiet darf nicht mehr als 64 Zeichen lang sein.',
      },
    },
  },
  introduction: {},
}
