<template>
  <div>
    <GoBack :to="{ name: 'TeammitgliederShow', params: { teammitgliedId } }">
      Teammitglied: &bdquo;{{ teammitglied.name }}&ldquo;
    </GoBack>

    <h1>Teammitglied bearbeiten</h1>

    <VForm @submit="submit">
      <FormBoxFields>
        <FormFieldInput
          field="name"
          label="Name*"
          fieldWidth="32rem"
          v-model="formData.name"
          :errors="formErrors"
          :rules="validationRules"
          :af="true"
        />

        <FormFieldInput
          field="role"
          label="Rolle*"
          fieldWidth="32rem"
          v-model="formData.role"
          :errors="formErrors"
          :rules="validationRules"
        />

        <FormFieldTextarea
          field="portrait"
          label="Portrait"
          fieldWidth="32rem"
          :rows="12"
          v-model="formData.portrait"
          :errors="formErrors"
          :rules="validationRules"
        />
      </FormBoxFields>

      <FormRowButtons>
        <VButton type="submit" :deactivated="submitted">SPEICHERN</VButton>
        <VButton
          type="link"
          :to="{ name: 'TeammitgliederShow', params: { teammitgliedId } }"
          mode="faint"
        >
          ABBRECHEN
        </VButton>
      </FormRowButtons>
    </VForm>
  </div>
</template>

<script>
import store from '@/store'
import formValidation from '@/assets/js/formValidation'
import validationRules from '@/assets/js/validationRules/teammitglieder'

import GoBack from '@/components/global/GoBack'
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/global/Button'

export default {
  name: 'RechtsanwaelteEdit',
  components: {
    GoBack,
    ...FormComponents,
    VButton,
  },
  props: ['teammitgliedId'],
  data() {
    return {
      formData: null,
      formErrors: [],
      validationRules,
      submitted: false,
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('teammitglieder/getOne', to.params.teammitgliedId)
    next()
  },
  created() {
    this.formData = {
      name: this.teammitglied.name ?? '',
      role: this.teammitglied.role ?? '',
      portrait: this.teammitglied.portrait ?? '',
    }
  },
  computed: {
    teammitglied: () => store.getters['teammitglieder/teammitglied'],
  },
  methods: {
    ...formValidation,
    async submit() {
      this.validateAll(this.formData, this.formErrors, this.validationRules)
      if (this.formErrors.length > 0) return

      this.submitted = true
      await this.$store.dispatch('teammitglieder/update', {
        id: this.teammitgliedId,
        formData: this.formData,
      })
      this.$router.push({
        name: 'TeammitgliederShow',
        params: { teammitgliedId: this.teammitgliedId },
      })
    },
  },
}
</script>
