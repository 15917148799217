<template>
  <div>
    <p>
      Eingeloggt mit dem Konto
      <strong>{{ user.email }}</strong>
    </p>

    <h1>Anleitung</h1>

    <h2>Das Menü</h2>
    <p>
      Das Menü ist entsprechend den Seiten der Website gegliedert. Für jede der Seiten mit
      veränderlichen Inhalten gibt es einen eigenen Menüpunkt.
    </p>
    <p></p>
    <p>
      Welche Sprach-Version der Website Sie bearbeiten wollen, stellen Sie ganz oben im Menüband
      ein. Behalten Sie diese Einstellung stets im Blick &mdash; das CMS ist für beide Sprachen
      ident aufgebaut.
    </p>
    <p></p>
    <p>Das Passwort ihres CMS-Kontos können Sie jederzeit im entsprechenden Menü-Punkt ändern.</p>
    <p></p>
    <p>
      Nach einer Ruhezeit von zwei Stunden werden Sie automatisch ausgeloggt. Sie können sich aber
      auch aktiv über den entsprechenden Button im Menü ausloggen.
    </p>

    <h2>Tabellen</h2>
    <p>
      Auf der Website gibt es viele Listen-artige Inhalte (Fachgebiete, Tätigkeitsbereiche,
      Publikationen, ...). Diese werden in Form von Tabellen angezeigt.
    </p>
    <p></p>
    <ListTable>
      <thead>
        <tr>
          <th>Fachgebiet</th>
          <th><OrderIcon class="table-icon" /></th>
        </tr>
      </thead>
      <TbodyDraggable>
        <tr>
          <TdLink :to="{ name: 'Dashboard' }">Immobilienrecht</TdLink>
          <td>
            <TableOrder :order="1" />
          </td>
        </tr>
        <tr>
          <TdLink :to="{ name: 'Dashboard' }">Gemeinderecht</TdLink>
          <td>
            <TableOrder :order="2" />
          </td>
        </tr>
        <tr>
          <TableAddItem :to="{ name: 'Dashboard' }" :colspan="2">
            Fachgebiet hinzufügen
          </TableAddItem>
        </tr>
      </TbodyDraggable>
    </ListTable>
    <p></p>
    <p>Die Reihenfolge der Einträge in diesen Tabellen kann auf zwei Wegen manipuliert werden:</p>
    <p>
      1. Sie können in das Positions-Feld die gewünschte Zielposition eintragen und mit der
      Eingabe-Taste bestätigen.
    </p>
    <p>
      2. Sie können den Eintrag am Verschiebe-Griff
      <span class="showcase-table-icon"><DragHandle /></span>
      per Drag-n-Drop an die gewünschte Position ziehen.
    </p>
    <p>In beiden Fällen wird die neue Position automatisch sofort gespeichert.</p>
    <p></p>
    <p>
      Wollen Sie einen Eintrag in der Tabelle bearbeiten, klicken Sie auf die Bezeichnung des
      Eintrages.
    </p>
    <p></p>
    <p>
      Wollen Sie einen Eintrag hinzufügen, klicken Sie auf den entsprechenden Button am Ende der
      Tabelle.
    </p>

    <h2>Formulare</h2>
    <p>Felder, die mit einem Stern (*) versehen sind, sind Pflichtfelder.</p>
    <p></p>
    <p><strong>Bilder</strong></p>
    <p></p>
    <p>Bitte laden Sie nur Bilder mit maximal 10 MB Dateigröße hoch.</p>
    <p></p>
    <p>
      Bitte achten Sie außerdem darauf, dass Sie Bilder verwenden, die im sRGB-Farbprofil
      abgespeichert sind. Der Browser konvertiert sonst beim Hochladen automatisch das Bild in den
      sRGB-Farbraum um, wodurch Farben und Kontraste verbleicht oder verfälscht werden können.
    </p>
    <p></p>
    <p>
      Hochgeladene Bilder werden automatisch auf dem Server optimiert. Dabei werden viele Kopien des
      Bildes in unterschiedlichen Größen und Dateiformaten angefertigt, und anschließend möglichst
      qualitätsbewahrend komprimiert. Dieser Prozess ist sehr rechenintensiv und kann daher mehrere
      Minuten in Anspruch nehmen.
    </p>

    <h2>Hinweise</h2>
    <p>
      Sie werden an unterschiedlichen Stellen im CMS dieses Fragezeichen-Symbol
      <FormInfo class="showcase-icon" title="Beispiel">Hinweis-Text</FormInfo>
      finden. Wenn Sie darauf klicken, erhalten Sie eine kurze Erklärung zum entsprechenden Thema.
    </p>

    <h2>„Seite anzeigen“</h2>
    <p>
      An manchen Stellen im CMS werden Sie einen Schalter mit dem Vermerk „Seite anzeigen“ finden:
    </p>
    <p></p>
    <VToggle name="beispiel" :value="true">
      Seite anzeigen
    </VToggle>
    <p></p>
    <p>
      Hier können Sie bestimmen, ob die entsprechende Seite in der gewählten Sprache angezeigt
      werden soll.
    </p>
    <p></p>
    <p>
      Bei den
      <strong>Fachgebieten</strong>
      können Sie so einstellen, ob eine eigene Seite für dieses Fachgebiet veröffentlicht werden
      soll. Wenn ja, dann erscheint das entsprechende Fachgebiet in den Fachgbiet-Listen dann auch
      als Link. Zum Zeitpunkt des Website-Launches haben sämtliche Fachgebiete im deutschen Bereich
      eine eigene Seite &mdash; im englischen hingegen keine.
    </p>
    <p></p>
    <p>
      Das gleiche Prinzip gilt für das
      <strong>Honorar</strong>
      . Zum Zeitpunkt des Website-Launches ist im deutschen Bereich eine Honorar-Seite
      veröffentlicht &mdash; nicht aber im englischen Bereich.
    </p>
  </div>
</template>

<script>
import store from '@/store'

import TableComponents from '@/components/TableComponents'
import FormInfo from '@/components/FormComponents/FormInfo'
import VToggle from '@/components/global/Toggle'

import DragHandle from '@/components/cmsIcons/DragHandle'
import OrderIcon from '@/components/cmsIcons/OrderIcon'

export default {
  name: 'Dashboard',
  components: {
    ...TableComponents,
    FormInfo,
    DragHandle,
    VToggle,
    OrderIcon,
  },
  computed: {
    lang: () => store.getters.lang,
    user: () => store.getters['auth/user'],
  },
}
</script>

<style lang="scss" scoped>
p {
  hyphens: auto;
  line-height: 1.5;

  &:empty:before {
    content: '\200b';
  }
}

strong {
  font-weight: 600;
}

em {
  font-style: italic;
}

u {
  text-decoration: underline;
}

a {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

h1 {
  margin-top: 4rem;
}

h2 {
  margin: 4rem 0 2rem;
}

.table-icon {
  height: 2rem;
  fill: $col-white;
}

.showcase-table-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.125em;
  vertical-align: bottom;
  background-color: $col-white;
  height: 1.5em;
  width: 1.5em;
  border-radius: $border-radius;
}

.showcase-icon {
  display: inline-block;
  margin: 0 0.125em;

  /deep/ .info-button {
    margin: 0;
  }
}
</style>
