<template>
  <picture>
    <source :srcset="srcset.webp" :sizes="sizes" type="image/webp" :media="media" />
    <source :srcset="srcset.jpg" :sizes="sizes" type="image/jpg" :media="media" />
    <!-- show 1x1px img per default as to avoid unnecessary loading -->
    <img
      class="responsive-image"
      src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
      :alt="alt"
    />
  </picture>
</template>

<script>
export default {
  name: 'ResponsiveImage',
  props: {
    urls: {
      type: Object,
      required: true,
    },
    sizes: {
      type: String,
      required: true,
    },
    media: {
      // media queries to avoid loading images when they are not needed
      type: String,
      default: '',
    },
    alt: {
      type: String,
      required: true,
    },
  },
  computed: {
    srcset() {
      const webp = this.urls.webp.reduce((acc, res) => acc + res.url + ' ' + res.size + 'w,', '')
      const jpg = this.urls.jpg.reduce((acc, res) => acc + res.url + ' ' + res.size + 'w,', '')
      return { webp, jpg }
    },
  },
}
</script>

<style scoped>
.responsive-image {
  display: block;
  position: relative;
  width: 100%;
}
</style>
