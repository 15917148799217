import axios from 'axios'

const getDefaultState = () => ({
  fachgebiete: [],
  fachgebiet: {},
})

const state = getDefaultState()

const getters = {
  fachgebiete: state => state.fachgebiete,
  fachgebiet: state => state.fachgebiet,
}

const mutations = {
  SET_ALL: (state, fachgebiete) => (state.fachgebiete = fachgebiete),

  SET_ONE: (state, fachgebiet) => (state.fachgebiet = fachgebiet),

  SET_POSITION: (state, { id, position }) => {
    const index = state.fachgebiete.findIndex(fachgebiet => fachgebiet.id == id)
    const removed = state.fachgebiete.splice(index, 1)[0]
    state.fachgebiete.splice(position - 1, 0, removed)
    let order = 1
    state.fachgebiete.forEach(fachgebiet => (fachgebiet.order = order++))
  },

  SET_DETAILS_PAGE_ACTIVATED: state => (state.fachgebiet.hasDetailsPage = true),

  SET_DETAILS_PAGE_DEACTIVATED: state => (state.fachgebiet.hasDetailsPage = false),
}

const actions = {
  getAll: async ({ commit, rootGetters }) => {
    const lang = rootGetters.lang
    const url = `${lang}/fachgebiete`
    const res = await axios.get(url)
    commit('SET_ALL', res.data.data)
    return res.data.data
  },

  getOne: async ({ commit, rootGetters }, id) => {
    const lang = rootGetters.lang
    const url = `${lang}/fachgebiete/${id}`
    const res = await axios.get(url)
    commit('SET_ONE', res.data.data)
    return res.data.data
  },

  create: async ({ dispatch, rootGetters }, formData) => {
    try {
      const lang = rootGetters.lang
      const url = `${lang}/fachgebiete`
      const res = await axios.post(url, formData)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Fachgebiet angelegt',
          mode: 'good',
        },
        { root: true }
      )
      return res.data.data
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Fachgebiet-Anlegen fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  update: async ({ commit, dispatch, rootGetters }, { id, formData }) => {
    try {
      const lang = rootGetters.lang
      const url = `${lang}/fachgebiete/${id}`
      formData.append('_method', 'PATCH')
      const res = await axios.post(url, formData)
      commit('SET_ONE', res.data.data)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Fachgebiet aktualisiert',
          mode: 'good',
        },
        { root: true }
      )
      return res.data.data
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Fachgebiet-Aktualisieren fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  delete: async ({ dispatch, rootGetters }, id) => {
    try {
      const lang = rootGetters.lang
      const url = `${lang}/fachgebiete/${id}`
      const res = await axios.delete(url)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Fachgebiet gelöscht',
          mode: 'good',
        },
        { root: true }
      )
      return res.data.data
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Fachgebiet-Löschen fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  setPosition: async ({ commit, dispatch, rootGetters }, payload) => {
    commit('SET_POSITION', payload)
    try {
      const lang = rootGetters.lang
      const url = `${lang}/fachgebiete/${payload.id}/set-position`
      const data = { position: payload.position }
      const res = await axios.post(url, data)
      return res.data.data
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Reihenfolge konnte nicht geändert werden - Bitte Seite aktualisieren',
          mode: 'bad',
          duration: 10000,
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  activateDetailsPage: async ({ commit, dispatch, rootGetters }, id) => {
    commit('SET_DETAILS_PAGE_ACTIVATED')
    try {
      const lang = rootGetters.lang
      const url = `/${lang}/fachgebiete/${id}/activate-details-page`
      await axios.post(url)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Fachgebiet-Seite publiziert',
          mode: 'good',
        },
        { root: true }
      )
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Fachgebiet-Seite-Publizieren fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  deactivateDetailsPage: async ({ commit, dispatch, rootGetters }, id) => {
    commit('SET_DETAILS_PAGE_DEACTIVATED')
    try {
      const lang = rootGetters.lang
      const url = `/${lang}/fachgebiete/${id}/deactivate-details-page`
      await axios.post(url)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Fachgebiet-Seite depubliziert',
          mode: 'good',
        },
        { root: true }
      )
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Fachgebiet-Seite-Depublizieren fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
