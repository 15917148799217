<template>
  <div>
    <h1>Fachgebiete</h1>

    <ListTable v-if="fachgebiete">
      <thead v-if="fachgebiete.length > 0">
        <tr>
          <th>Fachgebiet</th>
          <th><OrderIcon class="table-icon" /></th>
        </tr>
      </thead>

      <TbodyDraggable @end="onOrderEnd($event)">
        <tr v-for="({ id, title }, i) in fachgebiete" :key="id">
          <TdLink :to="{ name: 'FachgebieteShow', params: { fachgebietId: id } }">
            {{ title }}
          </TdLink>
          <td>
            <TableOrder :order="i + 1" @submit="setOrder({ id, position: $event })" />
          </td>
        </tr>
        <tr>
          <TableAddItem :to="{ name: 'FachgebieteCreate' }" :colspan="2">
            Fachgebiet hinzufügen
          </TableAddItem>
        </tr>
      </TbodyDraggable>
    </ListTable>
  </div>
</template>

<script>
import store from '@/store'

import TableComponents from '@/components/TableComponents'

import OrderIcon from '@/components/cmsIcons/OrderIcon'

export default {
  name: 'FachgebieteIndex',
  components: {
    ...TableComponents,
    OrderIcon,
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('fachgebiete/getAll')
    next()
  },
  computed: {
    fachgebiete: () => store.getters['fachgebiete/fachgebiete'],
  },
  methods: {
    setOrder(payload) {
      this.$store.dispatch('fachgebiete/setPosition', payload)
    },
    onOrderEnd(event) {
      const id = this.fachgebiete[event.oldIndex].id
      const position = event.newIndex + 1
      this.$store.dispatch('fachgebiete/setPosition', { id, position })
    },
  },
}
</script>

<style lang="scss" scoped>
.table-icon {
  height: 2rem;
  fill: $col-white;
}
</style>
