export default {
  kanzlei: {
    required: {
      message: {
        de: 'Bitte geben Sie einen Kanzlei-Teaser an.',
      },
    },
  },
  team: {},
  honorar: {},
}
