<template>
  <div>
    <h1>Kanzlei</h1>

    <content-box class="contentBox" v-html="kanzlei.article" maxWidth="40rem"></content-box>

    <VSpacer mode="small" />

    <div class="img">
      <ResponsiveImage :urls="kanzlei.bild" sizes="32rem" alt="Kanzlei" />
    </div>

    <VSpacer />

    <VButton type="link" :to="{ name: 'KanzleiEdit' }">
      BEARBEITEN
    </VButton>
  </div>
</template>

<script>
import store from '@/store'

import ContentBox from '@/components/global/ContentBox'
import VButton from '@/components/global/Button'
import VSpacer from '@/components/global/Spacer'
import ResponsiveImage from '@/components/ResponsiveImage'

export default {
  name: 'KanzleiIndex',
  components: {
    ContentBox,
    VButton,
    VSpacer,
    ResponsiveImage,
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('kanzlei/get')
    next()
  },
  computed: {
    kanzlei: () => store.getters['kanzlei/kanzlei'],
  },
}
</script>

<style scoped>
.contentBox {
  white-space: pre-wrap;
}

.img {
  width: 32rem;
}
</style>
