<template>
  <div>
    <GoBack :to="{ name: 'TeamIndex' }">Team</GoBack>

    <h1>Teammitglied anlegen</h1>

    <VForm @submit="submit">
      <FormBoxFields>
        <FormFieldInput
          field="name"
          label="Name*"
          fieldWidth="32rem"
          v-model="formData.name"
          :errors="formErrors"
          :rules="validationRules"
          :af="true"
        />

        <FormFieldInput
          field="role"
          label="Rolle*"
          fieldWidth="32rem"
          v-model="formData.role"
          :errors="formErrors"
          :rules="validationRules"
        />

        <FormFieldTextarea
          field="portrait"
          label="Portrait"
          fieldWidth="32rem"
          :rows="12"
          v-model="formData.portrait"
          :errors="formErrors"
          :rules="validationRules"
        />
      </FormBoxFields>

      <FormRowButtons>
        <VButton type="submit" :deactivated="submitted">SPEICHERN</VButton>
        <VButton type="link" :to="{ name: 'TeamIndex' }" mode="faint">ABBRECHEN</VButton>
      </FormRowButtons>
    </VForm>
  </div>
</template>

<script>
import formValidation from '@/assets/js/formValidation'
import validationRules from '@/assets/js/validationRules/teammitglieder'

import GoBack from '@/components/global/GoBack'
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/global/Button'

export default {
  name: 'TeammitgliederCreate',
  components: {
    GoBack,
    ...FormComponents,
    VButton,
  },
  data() {
    return {
      formData: {
        name: '',
        role: '',
        portrait: '',
      },
      formErrors: [],
      validationRules,
      submitted: false,
    }
  },
  methods: {
    ...formValidation,
    async submit() {
      this.validateAll(this.formData, this.formErrors, this.validationRules)
      if (this.formErrors.length > 0) return

      this.submitted = true
      const teammitglied = await this.$store.dispatch('teammitglieder/create', this.formData)
      this.$router.push({
        name: 'TeammitgliederShow',
        params: { teammitgliedId: teammitglied.id },
      })
    },
  },
}
</script>
