import axios from 'axios'

const getDefaultState = () => ({
  rechtsanwaelte: [],
  rechtsanwalt: {},
})

const state = getDefaultState()

const getters = {
  rechtsanwaelte: state => state.rechtsanwaelte,
  rechtsanwalt: state => state.rechtsanwalt,
}

const mutations = {
  SET_ALL: (state, rechtsanwaelte) => (state.rechtsanwaelte = rechtsanwaelte),

  SET_ONE: (state, rechtsanwalt) => (state.rechtsanwalt = rechtsanwalt),

  SET_POSITION: (state, { id, position }) => {
    const index = state.rechtsanwaelte.findIndex(rechtsanwalt => rechtsanwalt.id == id)
    const removed = state.rechtsanwaelte.splice(index, 1)[0]
    state.rechtsanwaelte.splice(position - 1, 0, removed)
    let order = 1
    state.rechtsanwaelte.forEach(rechtsanwalt => (rechtsanwalt.order = order++))
  },
}

const actions = {
  getAll: async ({ commit, rootGetters }) => {
    const lang = rootGetters.lang
    const url = `${lang}/rechtsanwaelte`
    const res = await axios.get(url)
    commit('SET_ALL', res.data.data)
    return res.data.data
  },

  getOne: async ({ commit, rootGetters }, id) => {
    const lang = rootGetters.lang
    const url = `${lang}/rechtsanwaelte/${id}`
    const res = await axios.get(url)
    commit('SET_ONE', res.data.data)
    return res.data.data
  },

  create: async ({ dispatch, rootGetters }, formData) => {
    try {
      const lang = rootGetters.lang
      const url = `${lang}/rechtsanwaelte`
      const res = await axios.post(url, formData)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Rechtsanwalt angelegt',
          mode: 'good',
        },
        { root: true }
      )
      return res.data.data
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Rechtsanwalt-Anlegen fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  update: async ({ commit, dispatch, rootGetters }, { id, formData }) => {
    try {
      const lang = rootGetters.lang
      const url = `${lang}/rechtsanwaelte/${id}`
      formData.append('_method', 'PATCH')
      const res = await axios.post(url, formData)
      commit('SET_ONE', res.data.data)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Rechtsanwalt aktualisiert',
          mode: 'good',
        },
        { root: true }
      )
      return res.data.data
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Rechtsanwalt-Aktualisieren fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  delete: async ({ dispatch, rootGetters }, id) => {
    try {
      const lang = rootGetters.lang
      const url = `${lang}/rechtsanwaelte/${id}`
      const res = await axios.delete(url)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Rechtsanwalt gelöscht',
          mode: 'good',
        },
        { root: true }
      )
      return res.data.data
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Rechtsanwalt-Löschen fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  setPosition: async ({ commit, dispatch, rootGetters }, payload) => {
    commit('SET_POSITION', payload)
    try {
      const lang = rootGetters.lang
      const url = `${lang}/rechtsanwaelte/${payload.id}/set-position`
      const data = { position: payload.position }
      const res = await axios.post(url, data)
      return res.data.data
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Reihenfolge konnte nicht geändert werden - Bitte Seite aktualisieren',
          mode: 'bad',
          duration: 10000,
        },
        { root: true }
      )
      throw err.response.data
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
