var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GoBack',{attrs:{"to":{ name: 'TeamIndex' }}},[_vm._v("Team")]),_c('h1',[_vm._v("Rechtsanwalt: „"+_vm._s(_vm.rechtsanwalt.name)+"“")]),_c('div',{staticClass:"img"},[_c('ResponsiveImage',{attrs:{"urls":_vm.rechtsanwalt.defaultBild,"sizes":"32rem","alt":"Standard-Bild"}})],1),_c('VSpacer',{attrs:{"mode":"small"}}),_c('div',{staticClass:"img"},[_c('ResponsiveImage',{attrs:{"urls":_vm.rechtsanwalt.hoverBild,"sizes":"32rem","alt":"Hover-Bild"}})],1),_c('VSpacer',{attrs:{"mode":"small"}}),_c('DetailsTable',[_c('tbody',[_c('tr',[_c('th',[_vm._v("Name")]),_c('td',[_vm._v(_vm._s(_vm.rechtsanwalt.name))])]),_c('tr',[_c('th',[_vm._v("Portrait")]),_c('td',[_vm._v(_vm._s(_vm.trimText(_vm.rechtsanwalt.portrait, 512)))])])])]),_c('VSpacer',{attrs:{"mode":"small"}}),_c('VButton',{attrs:{"type":"link","to":{ name: 'RechtsanwaelteEdit', params: { rechtsanwaltId: _vm.rechtsanwaltId } }}},[_vm._v(" BEARBEITEN ")]),_c('VButton',{staticClass:"delete-button",attrs:{"type":"button","mode":"danger"},on:{"click":function($event){return _vm.$refs.deleteModal.open()}}},[_vm._v(" LÖSCHEN ")]),_c('VSpacer'),_c('h2',[_vm._v("Tätigkeitsschwerpunkte")]),_c('VSpacer',{attrs:{"mode":"small"}}),(_vm.schwerpunkte)?_c('ListTable',[(_vm.schwerpunkte.length > 0)?_c('thead',[_c('tr',[_c('th',[_vm._v("Schwerpunkt")]),_c('th',[_c('OrderIcon',{staticClass:"table-icon"})],1)])]):_vm._e(),_c('TbodyDraggable',{on:{"end":function($event){return _vm.onOrderEnd($event, 'schwerpunkte')}}},[_vm._l((_vm.schwerpunkte),function(ref,i){
var id = ref.id;
var schwerpunkt = ref.schwerpunkt;
return _c('tr',{key:id},[_c('TdLink',{attrs:{"to":{ name: 'RechtsanwaltSchwerpunkteEdit', params: { schwerpunktId: id } }}},[_vm._v(" "+_vm._s(_vm.trimText(schwerpunkt, 48))+" ")]),_c('td',[_c('TableOrder',{attrs:{"order":i + 1},on:{"submit":function($event){return _vm.setOrder({ id: id, position: $event }, 'schwerpunkte')}}})],1)],1)}),_c('tr',[_c('TableAddItem',{attrs:{"to":{ name: 'RechtsanwaltSchwerpunkteCreate', query: { rechtsanwaltId: _vm.rechtsanwaltId } },"colspan":2}},[_vm._v(" Schwerpunkt hinzufügen ")])],1)],2)],1):_vm._e(),_c('VSpacer'),_c('h2',[_vm._v("Ausbildung")]),_c('VSpacer',{attrs:{"mode":"small"}}),(_vm.ausbildungen)?_c('ListTable',[(_vm.ausbildungen.length > 0)?_c('thead',[_c('tr',[_c('th',[_vm._v("Ausbildung")]),_c('th',[_c('OrderIcon',{staticClass:"table-icon"})],1)])]):_vm._e(),_c('TbodyDraggable',{on:{"end":function($event){return _vm.onOrderEnd($event, 'ausbildungen')}}},[_vm._l((_vm.ausbildungen),function(ref,i){
var id = ref.id;
var ausbildung = ref.ausbildung;
return _c('tr',{key:id},[_c('TdLink',{attrs:{"to":{ name: 'RechtsanwaltAusbildungenEdit', params: { ausbildungId: id } }}},[_vm._v(" "+_vm._s(_vm.trimText(ausbildung, 48))+" ")]),_c('td',[_c('TableOrder',{attrs:{"order":i + 1},on:{"submit":function($event){return _vm.setOrder({ id: id, position: $event }, 'ausbildungen')}}})],1)],1)}),_c('tr',[_c('TableAddItem',{attrs:{"to":{ name: 'RechtsanwaltAusbildungenCreate', query: { rechtsanwaltId: _vm.rechtsanwaltId } },"colspan":2}},[_vm._v(" Ausbildung hinzufügen ")])],1)],2)],1):_vm._e(),_c('VSpacer'),_c('h2',[_vm._v("Berufserfahrung")]),_c('VSpacer',{attrs:{"mode":"small"}}),(_vm.berufserfahrungen)?_c('ListTable',[(_vm.berufserfahrungen.length > 0)?_c('thead',[_c('tr',[_c('th',[_vm._v("Berufserfahrung")]),_c('th',[_c('OrderIcon',{staticClass:"table-icon"})],1)])]):_vm._e(),_c('TbodyDraggable',{on:{"end":function($event){return _vm.onOrderEnd($event, 'berufserfahrungen')}}},[_vm._l((_vm.berufserfahrungen),function(ref,i){
var id = ref.id;
var berufserfahrung = ref.berufserfahrung;
return _c('tr',{key:id},[_c('TdLink',{attrs:{"to":{ name: 'RechtsanwaltBerufserfahrungenEdit', params: { berufserfahrungId: id } }}},[_vm._v(" "+_vm._s(_vm.trimText(berufserfahrung, 48))+" ")]),_c('td',[_c('TableOrder',{attrs:{"order":i + 1},on:{"submit":function($event){return _vm.setOrder({ id: id, position: $event }, 'berufserfahrungen')}}})],1)],1)}),_c('tr',[_c('TableAddItem',{attrs:{"to":{ name: 'RechtsanwaltBerufserfahrungenCreate', query: { rechtsanwaltId: _vm.rechtsanwaltId } },"colspan":2}},[_vm._v(" Berufserfahrung hinzufügen ")])],1)],2)],1):_vm._e(),_c('VSpacer'),_c('h2',[_vm._v("Funktionen")]),_c('VSpacer',{attrs:{"mode":"small"}}),(_vm.funktionen)?_c('ListTable',[(_vm.funktionen.length > 0)?_c('thead',[_c('tr',[_c('th',[_vm._v("Funktion")]),_c('th',[_c('OrderIcon',{staticClass:"table-icon"})],1)])]):_vm._e(),_c('TbodyDraggable',{on:{"end":function($event){return _vm.onOrderEnd($event, 'funktionen')}}},[_vm._l((_vm.funktionen),function(ref,i){
var id = ref.id;
var funktion = ref.funktion;
return _c('tr',{key:id},[_c('TdLink',{attrs:{"to":{ name: 'RechtsanwaltFunktionenEdit', params: { funktionId: id } }}},[_vm._v(" "+_vm._s(_vm.trimText(funktion, 48))+" ")]),_c('td',[_c('TableOrder',{attrs:{"order":i + 1},on:{"submit":function($event){return _vm.setOrder({ id: id, position: $event }, 'funktionen')}}})],1)],1)}),_c('tr',[_c('TableAddItem',{attrs:{"to":{ name: 'RechtsanwaltFunktionenCreate', query: { rechtsanwaltId: _vm.rechtsanwaltId } },"colspan":2}},[_vm._v(" Funktion hinzufügen ")])],1)],2)],1):_vm._e(),_c('VSpacer'),_c('h2',[_vm._v("Publikationen")]),_c('VSpacer',{attrs:{"mode":"small"}}),(_vm.publikationen)?_c('ListTable',[(_vm.publikationen.length > 0)?_c('thead',[_c('tr',[_c('th',[_vm._v("Publikation")]),_c('th',[_c('OrderIcon',{staticClass:"table-icon"})],1)])]):_vm._e(),_c('TbodyDraggable',{on:{"end":function($event){return _vm.onOrderEnd($event, 'publikationen')}}},[_vm._l((_vm.publikationen),function(ref,i){
var id = ref.id;
var publikation = ref.publikation;
return _c('tr',{key:id},[_c('TdLink',{attrs:{"to":{ name: 'RechtsanwaltPublikationenEdit', params: { publikationId: id } }}},[_vm._v(" "+_vm._s(_vm.trimText(publikation, 48))+" ")]),_c('td',[_c('TableOrder',{attrs:{"order":i + 1},on:{"submit":function($event){return _vm.setOrder({ id: id, position: $event }, 'publikationen')}}})],1)],1)}),_c('tr',[_c('TableAddItem',{attrs:{"to":{ name: 'RechtsanwaltPublikationenCreate', query: { rechtsanwaltId: _vm.rechtsanwaltId } },"colspan":2}},[_vm._v(" Publikation hinzufügen ")])],1)],2)],1):_vm._e(),_c('ModalConfirm',{ref:"deleteModal",on:{"confirm":_vm.deleteRechtsanwalt}},[_vm._v(" Sind Sie sicher, dass Sie diesen Rechtsanwalt löschen wollen? ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }