<template>
  <div>
    <GoBack :to="{ name: 'TeamIndex' }">Team</GoBack>

    <h1>Rechtsanwalt anlegen</h1>

    <VForm @submit="submit">
      <FormBoxFields>
        <FormFieldInput
          field="name"
          label="Name*"
          fieldWidth="32rem"
          v-model="formData.name"
          :errors="formErrors"
          :rules="validationRules"
          :af="true"
        />

        <FormFieldInput
          field="role"
          label="Rolle*"
          fieldWidth="32rem"
          v-model="formData.role"
          :errors="formErrors"
          :rules="validationRules"
        />

        <FormFieldTextarea
          field="portrait"
          label="Portrait*"
          fieldWidth="32rem"
          :rows="12"
          v-model="formData.portrait"
          :errors="formErrors"
          :rules="validationRules"
        />

        <FormRowFields>
          <FormLabelInline for="defaultBild">
            Standard-Bild*
            <FormInfo title="Standard-Bild">
              Das reguläre Portrait-Foto. Bitte stellen Sie sicher, dass das Bild im sRGB-Farbprofil
              abgespeichert ist, um unerwünschte Farbveränderungen zu vermeiden. Maximal 10 MB.
            </FormInfo>
          </FormLabelInline>
          <input type="file" name="defaultBild" ref="defaultBild" required />
        </FormRowFields>

        <FormRowFields>
          <FormLabelInline for="hoverBild">
            Hover-Bild*
            <FormInfo title="Hover-Bild">
              Jenes Foto, das man sieht, wenn man mit der Maus über das reguläre Portrait-Foto
              streicht. Bitte stellen Sie sicher, dass das Bild im sRGB-Farbprofil abgespeichert
              ist, um unerwünschte Farbveränderungen zu vermeiden. Maximal 10 MB.
            </FormInfo>
          </FormLabelInline>
          <input type="file" name="hoverBild" ref="hoverBild" required />
        </FormRowFields>
      </FormBoxFields>

      <FormRowButtons>
        <VButton type="submit" :deactivated="submitted">SPEICHERN</VButton>
        <VButton type="link" :to="{ name: 'TeamIndex' }" mode="faint">
          ABBRECHEN
        </VButton>
      </FormRowButtons>
    </VForm>

    <VModal ref="imageOptimizationsModal">
      <template slot="title">Bitte warten...</template>
      <p>
        Die Bilder werden für unterschiedliche Bildschirmgrößen optimiert. Dieser Prozess kann
        mehrere Minuten dauern.
      </p>
    </VModal>
  </div>
</template>

<script>
import formValidation from '@/assets/js/formValidation'
import validationRules from '@/assets/js/validationRules/rechtsanwaelte'

import GoBack from '@/components/global/GoBack'
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/global/Button'
import VModal from '@/components/global/Modal'

export default {
  name: 'RechtsanwaelteCreate',
  components: {
    GoBack,
    ...FormComponents,
    VButton,
    VModal,
  },
  data() {
    return {
      formData: {
        name: '',
        role: 'Rechtsanwalt',
        portrait: '',
      },
      formErrors: [],
      validationRules,
      submitted: false,
    }
  },
  methods: {
    ...formValidation,
    async submit() {
      this.validateAll(this.formData, this.formErrors, this.validationRules)
      if (this.formErrors.length > 0) return

      this.submitted = true
      let formData = new FormData()
      Object.entries(this.formData).forEach(([field, value]) => {
        formData.append(field, value)
      })
      formData.append('default_bild', this.$refs.defaultBild.files[0])
      formData.append('hover_bild', this.$refs.hoverBild.files[0])
      this.$refs.imageOptimizationsModal.open()
      const rechtsanwalt = await this.$store.dispatch('rechtsanwaelte/create', formData)
      this.$router.push({
        name: 'RechtsanwaelteShow',
        params: { rechtsanwaltId: rechtsanwalt.id },
      })
    },
  },
}
</script>
