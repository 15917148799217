<template>
  <div>
    <h1>Datenschutz</h1>

    <content-box class="contentBox" v-html="datenschutz.article" maxWidth="40rem"></content-box>

    <VSpacer mode="small" />

    <VButton type="link" :to="{ name: 'DatenschutzEdit' }">
      BEARBEITEN
    </VButton>
  </div>
</template>

<script>
import store from '@/store'

import ContentBox from '@/components/global/ContentBox'
import VButton from '@/components/global/Button'
import VSpacer from '@/components/global/Spacer'

export default {
  name: 'DatenschutzIndex',
  components: {
    ContentBox,
    VButton,
    VSpacer,
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('datenschutz/get')
    next()
  },
  computed: {
    datenschutz: () => store.getters['datenschutz/datenschutz'],
  },
}
</script>
