<template>
  <div>
    <GoBack :to="{ name: 'RechtsanwaelteShow', params: { rechtsanwaltId: rechtsanwalt.id } }">
      Rechtsanwalt: &bdquo;{{ rechtsanwalt.name }}&ldquo;
    </GoBack>

    <h1>Ausbildung bearbeiten</h1>

    <VForm @submit="submit">
      <FormBoxFields>
        <FormFieldInput
          field="ausbildung"
          label="Ausbildung*"
          fieldWidth="32rem"
          v-model="formData.ausbildung"
          :errors="formErrors"
          :rules="validationRules"
          :af="true"
        />
      </FormBoxFields>

      <FormRowButtons>
        <VButton type="submit" :deactivated="submitted">SPEICHERN</VButton>
        <VButton
          type="link"
          :to="{ name: 'RechtsanwaelteShow', params: { rechtsanwaltId: rechtsanwalt.id } }"
          mode="faint"
        >
          ABBRECHEN
        </VButton>
      </FormRowButtons>

      <FormRowButtons>
        <VButton type="button" mode="danger" @click="$refs.deleteModal.open()">
          LÖSCHEN
        </VButton>
      </FormRowButtons>
    </VForm>

    <ModalConfirm ref="deleteModal" @confirm="deleteAusbildung">
      Sind Sie sicher, dass Sie diese Ausbildung löschen wollen?
    </ModalConfirm>
  </div>
</template>

<script>
import store from '@/store'
import formValidation from '@/assets/js/formValidation'
import validationRules from '@/assets/js/validationRules/rechtsanwaltAusbildungen'

import GoBack from '@/components/global/GoBack'
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/global/Button'
import ModalConfirm from '@/components/global/ModalConfirm'

export default {
  name: 'RechtsanwaltAusbildungenEdit',
  components: {
    GoBack,
    ...FormComponents,
    VButton,
    ModalConfirm,
  },
  props: ['ausbildungId'],
  data() {
    return {
      formData: null,
      formErrors: [],
      validationRules,
      submitted: false,
    }
  },
  async beforeRouteEnter(to, from, next) {
    const ausbildung = await store.dispatch(
      'rechtsanwaltAusbildungen/getOne',
      to.params.ausbildungId
    )
    await store.dispatch('rechtsanwaelte/getOne', ausbildung.rechtsanwaltId)
    next()
  },
  created() {
    this.formData = {
      ausbildung: this.ausbildung.ausbildung ?? '',
    }
  },
  computed: {
    ausbildung: () => store.getters['rechtsanwaltAusbildungen/ausbildung'],
    rechtsanwalt: () => store.getters['rechtsanwaelte/rechtsanwalt'],
  },
  methods: {
    ...formValidation,
    async submit() {
      this.validateAll(this.formData, this.formErrors, this.validationRules)
      if (this.formErrors.length > 0) return

      this.submitted = true
      await this.$store.dispatch('rechtsanwaltAusbildungen/update', {
        id: this.ausbildungId,
        formData: this.formData,
      })
      this.$router.push({
        name: 'RechtsanwaelteShow',
        params: { rechtsanwaltId: this.rechtsanwalt.id },
      })
    },
    async deleteAusbildung() {
      await this.$store.dispatch('rechtsanwaltAusbildungen/delete', this.ausbildungId)
      this.$router.push({
        name: 'RechtsanwaelteShow',
        params: { rechtsanwaltId: this.rechtsanwalt.id },
      })
    },
  },
}
</script>
