export default {
  name: {
    required: {
      message: {
        de: 'Bitte geben Sie einen Namen an.',
      },
    },
    max: {
      characters: 64,
      message: {
        de: 'Der Name darf nicht mehr als 64 Zeichen lang sein.',
      },
    },
  },
  role: {
    required: {
      message: {
        de: 'Bitte geben Sie eine Rolle an.',
      },
    },
    max: {
      characters: 64,
      message: {
        de: 'Die Rolle darf nicht mehr als 64 Zeichen lang sein.',
      },
    },
  },
  portrait: {},
}
