<template>
  <div>
    <GoBack :to="{ name: 'FachgebieteIndex' }">Fachgebiete</GoBack>

    <h1>Fachgebiet anlegen</h1>

    <VForm @submit="submit">
      <FormBoxFields>
        <FormFieldInput
          field="title"
          label="Fachgebiet*"
          fieldWidth="32rem"
          v-model="formData.title"
          :errors="formErrors"
          :rules="validationRules"
          :af="true"
        />

        <FormFieldTextarea
          field="introduction"
          label="Einleitung"
          fieldWidth="32rem"
          :rows="12"
          v-model="formData.introduction"
          :errors="formErrors"
          :rules="validationRules"
        />

        <FormRowFields>
          <FormLabelInline for="bild">
            Bild
            <FormInfo title="Bild">
              Bitte stellen Sie sicher, dass das Bild im sRGB-Farbprofil abgespeichert ist, um
              unerwünschte Farbveränderungen zu vermeiden. Maximal 10 MB.
            </FormInfo>
          </FormLabelInline>
          <input type="file" name="bild" ref="bild" />
        </FormRowFields>
      </FormBoxFields>

      <FormRowButtons>
        <VButton type="submit" :deactivated="submitted">SPEICHERN</VButton>
        <VButton type="link" :to="{ name: 'FachgebieteIndex' }" mode="faint">ABBRECHEN</VButton>
      </FormRowButtons>
    </VForm>

    <VModal ref="imageOptimizationsModal">
      <template slot="title">Bitte warten...</template>
      <p>
        Das Bild wird für unterschiedliche Bildschirmgrößen optimiert. Dieser Prozess kann mehrere
        Minuten dauern.
      </p>
    </VModal>
  </div>
</template>

<script>
import formValidation from '@/assets/js/formValidation'
import validationRules from '@/assets/js/validationRules/fachgebiete'

import GoBack from '@/components/global/GoBack'
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/global/Button'
import VModal from '@/components/global/Modal'

export default {
  name: 'FachgebieteCreate',
  components: {
    GoBack,
    ...FormComponents,
    VButton,
    VModal,
  },
  data() {
    return {
      formData: {
        title: '',
        introduction: '',
      },
      formErrors: [],
      validationRules,
      submitted: false,
    }
  },
  methods: {
    ...formValidation,
    async submit() {
      this.validateAll(this.formData, this.formErrors, this.validationRules)
      if (this.formErrors.length > 0) return

      this.submitted = true
      let formData = new FormData()
      Object.entries(this.formData).forEach(([field, value]) => {
        formData.append(field, value)
      })
      if (this.$refs.bild.files?.[0]) {
        formData.append('bild', this.$refs.bild.files[0])
        this.$refs.imageOptimizationsModal.open()
      }
      const fachgebiet = await this.$store.dispatch('fachgebiete/create', formData)
      this.$router.push({
        name: 'FachgebieteShow',
        params: { fachgebietId: fachgebiet.id },
      })
    },
  },
}
</script>
