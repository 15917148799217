import axios from 'axios'

const getDefaultState = () => ({
  honorar: {},
})

const state = getDefaultState()

const getters = {
  honorar: state => state.honorar,
}

const mutations = {
  SET: (state, honorar) => (state.honorar = honorar),
  SET_PUBLISHED: state => (state.honorar.published = true),
  SET_PRIVATE: state => (state.honorar.published = false),
}

const actions = {
  get: async ({ commit, rootGetters }) => {
    const lang = rootGetters.lang
    const url = `${lang}/honorar`
    const res = await axios.get(url)
    commit('SET', res.data.data)
    return res.data.data
  },

  update: async ({ commit, dispatch, rootGetters }, formData) => {
    try {
      const lang = rootGetters.lang
      const url = `${lang}/honorar`
      const res = await axios.patch(url, formData)
      commit('SET', res.data.data)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Honorar aktualisiert',
          mode: 'good',
        },
        { root: true }
      )
      return res.data.data
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Honorar-Aktualisieren fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  publish: async ({ commit, dispatch, rootGetters }) => {
    commit('SET_PUBLISHED')
    try {
      const lang = rootGetters.lang
      const url = `/${lang}/honorar/publish`
      await axios.post(url)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Honorar-Seite publiziert',
          mode: 'good',
        },
        { root: true }
      )
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Honorar-Seite-Publizieren fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },

  unpublish: async ({ commit, dispatch, rootGetters }) => {
    commit('SET_PRIVATE')
    try {
      const lang = rootGetters.lang
      const url = `/${lang}/honorar/unpublish`
      await axios.post(url)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Honorar-Seite depubliziert',
          mode: 'good',
        },
        { root: true }
      )
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Honorar-Seite-Depublizieren fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
