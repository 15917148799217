<template>
  <div>
    <h1>Home</h1>

    <h2>Kanzlei</h2>

    <content-box class="contentBox" v-html="home.kanzlei" maxWidth="40rem"></content-box>

    <VSpacer mode="small" />

    <h2>Team</h2>

    <content-box class="contentBox" v-html="home.team" maxWidth="40rem"></content-box>

    <VSpacer mode="small" />

    <h2>Honorar</h2>

    <content-box class="contentBox" v-html="home.honorar" maxWidth="40rem"></content-box>

    <VSpacer mode="small" />

    <VButton type="link" :to="{ name: 'HomeEdit' }">
      BEARBEITEN
    </VButton>
  </div>
</template>

<script>
import store from '@/store'

import ContentBox from '@/components/global/ContentBox'
import VButton from '@/components/global/Button'
import VSpacer from '@/components/global/Spacer'

export default {
  name: 'HomeIndex',
  components: {
    ContentBox,
    VButton,
    VSpacer,
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('home/get')
    next()
  },
  computed: {
    home: () => store.getters['home/home'],
  },
}
</script>

<style scoped>
.contentBox {
  white-space: pre-wrap;
}
</style>
