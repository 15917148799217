<template>
  <div>
    <GoBack :to="{ name: 'HonorarIndex' }">Honorar</GoBack>

    <h1>Honorar bearbeiten</h1>

    <VForm @submit="submit">
      <FormRowEditor v-model="formData.article" :features="['bold', 'italic', 'link', 'heading']" />

      <FormRowButtons>
        <VButton type="submit" :deactivated="submitted">SPEICHERN</VButton>
        <VButton type="link" :to="{ name: 'HonorarIndex' }" mode="faint">ABBRECHEN</VButton>
      </FormRowButtons>
    </VForm>
  </div>
</template>

<script>
import store from '@/store'
import formValidation from '@/assets/js/formValidation'
import validationRules from '@/assets/js/validationRules/honorar'

import GoBack from '@/components/global/GoBack'
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/global/Button'

export default {
  name: 'HonorarEdit',
  components: {
    GoBack,
    ...FormComponents,
    VButton,
  },
  data() {
    return {
      formData: null,
      formErrors: [],
      validationRules,
      submitted: false,
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('honorar/get')
    next()
  },
  created() {
    this.formData = {
      article: this.honorar.article ?? '',
    }
  },
  computed: {
    honorar: () => store.getters['honorar/honorar'],
  },
  methods: {
    ...formValidation,
    async submit() {
      this.validateAll(this.formData, this.formErrors, this.validationRules)
      if (this.formErrors.length > 0) return

      this.submitted = true
      await this.$store.dispatch('honorar/update', this.formData)
      this.$router.push({ name: 'HonorarIndex' })
    },
  },
}
</script>
