<template>
  <div>
    <h1>Passwort ändern</h1>

    <VForm @submit="submit">
      <FormError v-if="Object.entries(apiErrors).length">
        {{ apiErrors[Object.keys(apiErrors)[0]][0] }}
      </FormError>

      <FormBoxFields>
        <FormFieldInput
          field="password_current"
          label="Aktuelles Passwort*"
          type="password"
          v-model="formData.password_current"
          :errors="formErrors"
          :rules="validationRules"
          :af="true"
        />

        <FormFieldInput
          field="password_new"
          label="Neues Passwort*"
          type="password"
          v-model="formData.password_new"
          :errors="formErrors"
          :rules="validationRules"
        />

        <FormFieldInput
          field="password_repeat"
          label="Neues Passwort wiederholen*"
          type="password"
          v-model="formData.password_repeat"
          :errors="formErrors"
          :rules="validationRules"
        />
      </FormBoxFields>

      <FormRowButtons>
        <VButton type="submit" :deactivated="submitted">SPEICHERN</VButton>
        <VButton type="link" :to="{ name: 'Dashboard' }" mode="faint">ABBRECHEN</VButton>
      </FormRowButtons>
    </VForm>
  </div>
</template>

<script>
import formValidation from '@/assets/js/formValidation'
import validationRules from '@/assets/js/validationRules/passwortAendern'

import FormComponents from '@/components/FormComponents'
import VButton from '@/components/global/Button'
export default {
  name: 'PasswortAendern',
  components: {
    ...FormComponents,
    VButton,
  },
  data() {
    return {
      formData: {
        password_current: '',
        password_new: '',
        password_repeat: '',
      },
      formErrors: [],
      validationRules,
      submitted: false,
      apiErrors: {},
    }
  },
  methods: {
    ...formValidation,
    async submit() {
      this.validateAll(this.formData, this.formErrors, this.validationRules)
      if (this.formErrors.length > 0) return

      this.submitted = true

      try {
        await this.$store.dispatch('auth/updatePassword', this.formData)
        this.$router.push({ name: 'Dashboard' })
      } catch (err) {
        this.formData.password_current = ''
        this.formData.password_new = ''
        this.formData.password_repeat = ''
        this.apiErrors = err.errors
        this.submitted = false
      }
    },
  },
}
</script>
