import axios from 'axios'

const getDefaultState = () => ({
  datenschutz: {},
})

const state = getDefaultState()

const getters = {
  datenschutz: state => state.datenschutz,
}

const mutations = {
  SET: (state, datenschutz) => (state.datenschutz = datenschutz),
}

const actions = {
  get: async ({ commit, rootGetters }) => {
    const lang = rootGetters.lang
    const url = `${lang}/datenschutz`
    const res = await axios.get(url)
    commit('SET', res.data.data)
    return res.data.data
  },

  update: async ({ commit, dispatch, rootGetters }, formData) => {
    try {
      const lang = rootGetters.lang
      const url = `${lang}/datenschutz`
      const res = await axios.patch(url, formData)
      commit('SET', res.data.data)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Datenschutz aktualisiert',
          mode: 'good',
        },
        { root: true }
      )
      return res.data.data
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Datenschutz-Aktualisieren fehlgeschlagen',
          mode: 'bad',
        },
        { root: true }
      )
      throw err.response.data
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
