<template>
  <div>
    <GoBack :to="{ name: 'RechtsanwaelteShow', params: { rechtsanwaltId: rechtsanwalt.id } }">
      Rechtsanwalt: &bdquo;{{ rechtsanwalt.name }}&ldquo;
    </GoBack>

    <h1>Funktion bearbeiten</h1>

    <VForm @submit="submit">
      <FormBoxFields>
        <FormFieldInput
          field="funktion"
          label="Funktion*"
          fieldWidth="32rem"
          v-model="formData.funktion"
          :errors="formErrors"
          :rules="validationRules"
          :af="true"
        />
      </FormBoxFields>

      <FormRowButtons>
        <VButton type="submit" :deactivated="submitted">SPEICHERN</VButton>
        <VButton
          type="link"
          :to="{ name: 'RechtsanwaelteShow', params: { rechtsanwaltId: rechtsanwalt.id } }"
          mode="faint"
        >
          ABBRECHEN
        </VButton>
      </FormRowButtons>

      <FormRowButtons>
        <VButton type="button" mode="danger" @click="$refs.deleteModal.open()">
          LÖSCHEN
        </VButton>
      </FormRowButtons>
    </VForm>

    <ModalConfirm ref="deleteModal" @confirm="deleteFunktion">
      Sind Sie sicher, dass Sie diese Funktion löschen wollen?
    </ModalConfirm>
  </div>
</template>

<script>
import store from '@/store'
import formValidation from '@/assets/js/formValidation'
import validationRules from '@/assets/js/validationRules/rechtsanwaltFunktionen'

import GoBack from '@/components/global/GoBack'
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/global/Button'
import ModalConfirm from '@/components/global/ModalConfirm'

export default {
  name: 'RechtsanwaltAusbildungenEdit',
  components: {
    GoBack,
    ...FormComponents,
    VButton,
    ModalConfirm,
  },
  props: ['funktionId'],
  data() {
    return {
      formData: null,
      formErrors: [],
      validationRules,
      submitted: false,
    }
  },
  async beforeRouteEnter(to, from, next) {
    const funktion = await store.dispatch('rechtsanwaltFunktionen/getOne', to.params.funktionId)
    await store.dispatch('rechtsanwaelte/getOne', funktion.rechtsanwaltId)
    next()
  },
  created() {
    this.formData = {
      funktion: this.funktion.funktion ?? '',
    }
  },
  computed: {
    funktion: () => store.getters['rechtsanwaltFunktionen/funktion'],
    rechtsanwalt: () => store.getters['rechtsanwaelte/rechtsanwalt'],
  },
  methods: {
    ...formValidation,
    async submit() {
      this.validateAll(this.formData, this.formErrors, this.validationRules)
      if (this.formErrors.length > 0) return

      this.submitted = true
      await this.$store.dispatch('rechtsanwaltFunktionen/update', {
        id: this.funktionId,
        formData: this.formData,
      })
      this.$router.push({
        name: 'RechtsanwaelteShow',
        params: { rechtsanwaltId: this.rechtsanwalt.id },
      })
    },
    async deleteFunktion() {
      await this.$store.dispatch('rechtsanwaltFunktionen/delete', this.funktionId)
      this.$router.push({
        name: 'RechtsanwaelteShow',
        params: { rechtsanwaltId: this.rechtsanwalt.id },
      })
    },
  },
}
</script>
