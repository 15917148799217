import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'

import auth from '@/store/auth'
import flashMessage from '@/store/flashMessage'

import home from '@/store/home'

import kanzlei from '@/store/kanzlei'

import fachgebiete from '@/store/fachgebiete'
import fachgebietKernkompetenzen from '@/store/fachgebietKernkompetenzen'

import rechtsanwaelte from '@/store/rechtsanwaelte'
import rechtsanwaltAusbildungen from '@/store/rechtsanwaltAusbildungen'
import rechtsanwaltBerufserfahrungen from '@/store/rechtsanwaltBerufserfahrungen'
import rechtsanwaltFunktionen from '@/store/rechtsanwaltFunktionen'
import rechtsanwaltSchwerpunkte from '@/store/rechtsanwaltSchwerpunkte'
import rechtsanwaltPublikationen from '@/store/rechtsanwaltPublikationen'
import teammitglieder from '@/store/teammitglieder'

import honorar from '@/store/honorar'

import datenschutz from '@/store/datenschutz'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lang: 'de',
  },
  getters: {
    lang: state => state.lang,
  },
  mutations: {
    SET_LANG: (state, lang) => {
      state.lang = lang
      router.push({ name: 'Dashboard' })
    },
  },
  actions: {},
  modules: {
    auth,
    flashMessage,
    home,
    kanzlei,
    fachgebiete,
    fachgebietKernkompetenzen,
    rechtsanwaelte,
    rechtsanwaltAusbildungen,
    rechtsanwaltBerufserfahrungen,
    rechtsanwaltFunktionen,
    rechtsanwaltSchwerpunkte,
    rechtsanwaltPublikationen,
    teammitglieder,
    honorar,
    datenschutz,
  },
})
