<template>
  <div>
    <h1>Team</h1>

    <h2>Rechtsanwaelte</h2>

    <ListTable v-if="rechtsanwaelte">
      <thead v-if="rechtsanwaelte.length > 0">
        <tr>
          <th>Name</th>
          <th><OrderIcon class="table-icon" /></th>
        </tr>
      </thead>

      <TbodyDraggable @end="onOrderEnd($event, 'rechtsanwaelte')">
        <tr v-for="({ id, name }, i) in rechtsanwaelte" :key="id">
          <TdLink :to="{ name: 'RechtsanwaelteShow', params: { rechtsanwaltId: id } }">
            {{ name }}
          </TdLink>
          <td>
            <TableOrder
              :order="i + 1"
              @submit="setOrder({ id, position: $event }, 'rechtsanwaelte')"
            />
          </td>
        </tr>
        <tr>
          <TableAddItem :to="{ name: 'RechtsanwaelteCreate' }" :colspan="2">
            Rechtsanwalt hinzufügen
          </TableAddItem>
        </tr>
      </TbodyDraggable>
    </ListTable>

    <VSpacer />

    <h2>Weitere Teammitglieder</h2>

    <ListTable v-if="teammitglieder">
      <thead v-if="teammitglieder.length > 0">
        <tr>
          <th>Name</th>
          <th><OrderIcon class="table-icon" /></th>
        </tr>
      </thead>

      <TbodyDraggable @end="onOrderEnd($event, 'teammitglieder')">
        <tr v-for="({ id, name }, i) in teammitglieder" :key="id">
          <TdLink :to="{ name: 'TeammitgliederShow', params: { teammitgliedId: id } }">
            {{ name }}
          </TdLink>
          <td>
            <TableOrder
              :order="i + 1"
              @submit="setOrder({ id, position: $event }, 'teammitglieder')"
            />
          </td>
        </tr>
        <tr>
          <TableAddItem :to="{ name: 'TeammitgliederCreate' }" :colspan="2">
            Teammitglied hinzufügen
          </TableAddItem>
        </tr>
      </TbodyDraggable>
    </ListTable>
  </div>
</template>

<script>
import store from '@/store'

import TableComponents from '@/components/TableComponents'
import VSpacer from '@/components/global/Spacer'

import OrderIcon from '@/components/cmsIcons/OrderIcon'

export default {
  name: 'TeamIndex',
  components: {
    ...TableComponents,
    VSpacer,
    OrderIcon,
  },
  async beforeRouteEnter(to, from, next) {
    await Promise.all([
      store.dispatch('rechtsanwaelte/getAll'),
      store.dispatch('teammitglieder/getAll'),
    ])
    next()
  },
  computed: {
    rechtsanwaelte: () => store.getters['rechtsanwaelte/rechtsanwaelte'],
    teammitglieder: () => store.getters['teammitglieder/teammitglieder'],
  },
  methods: {
    setOrder(payload, type) {
      this.$store.dispatch(`${type}/setPosition`, payload)
    },
    onOrderEnd(event, type) {
      const id = this[type][event.oldIndex].id
      const position = event.newIndex + 1
      this.$store.dispatch(`${type}/setPosition`, { id, position })
    },
  },
}
</script>

<style lang="scss" scoped>
.table-icon {
  height: 2rem;
  fill: $col-white;
}
</style>
