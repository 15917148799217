<template>
  <div>
    <GoBack :to="{ name: 'FachgebieteShow', params: { fachgebietId: fachgebiet.id } }">
      Fachgebiet: &bdquo;{{ fachgebiet.title }}&ldquo;
    </GoBack>

    <h1>Kernkompetenz bearbeiten</h1>

    <VForm @submit="submit">
      <FormBoxFields>
        <FormFieldTextarea
          field="kompetenz"
          label="Kernkompetenz*"
          fieldWidth="32rem"
          :rows="4"
          v-model="formData.kompetenz"
          :errors="formErrors"
          :rules="validationRules"
          :af="true"
        />
      </FormBoxFields>

      <FormRowButtons>
        <VButton type="submit" :deactivated="submitted">SPEICHERN</VButton>
        <VButton
          type="link"
          :to="{ name: 'FachgebieteShow', params: { fachgebietId: fachgebiet.id } }"
          mode="faint"
        >
          ABBRECHEN
        </VButton>
      </FormRowButtons>

      <FormRowButtons>
        <VButton type="button" mode="danger" @click="$refs.deleteModal.open()">
          LÖSCHEN
        </VButton>
      </FormRowButtons>
    </VForm>

    <ModalConfirm ref="deleteModal" @confirm="deleteKernkompetenz">
      Sind Sie sicher, dass Sie diese Kernkompetenz löschen wollen?
    </ModalConfirm>
  </div>
</template>

<script>
import store from '@/store'
import formValidation from '@/assets/js/formValidation'
import validationRules from '@/assets/js/validationRules/fachgebietKernkompetenzen'

import GoBack from '@/components/global/GoBack'
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/global/Button'
import ModalConfirm from '@/components/global/ModalConfirm'

export default {
  name: 'FachgebietKernkompetenzenEdit',
  components: {
    GoBack,
    ...FormComponents,
    VButton,
    ModalConfirm,
  },
  props: ['kernkompetenzId'],
  data() {
    return {
      formData: null,
      formErrors: [],
      validationRules,
      submitted: false,
    }
  },
  async beforeRouteEnter(to, from, next) {
    const kernkompetenz = await store.dispatch(
      'fachgebietKernkompetenzen/getOne',
      to.params.kernkompetenzId
    )
    await store.dispatch('fachgebiete/getOne', kernkompetenz.fachgebietId)
    next()
  },
  created() {
    this.formData = {
      kompetenz: this.kernkompetenz.kompetenz ?? '',
    }
  },
  computed: {
    kernkompetenz: () => store.getters['fachgebietKernkompetenzen/kernkompetenz'],
    fachgebiet: () => store.getters['fachgebiete/fachgebiet'],
  },
  methods: {
    ...formValidation,
    async submit() {
      this.validateAll(this.formData, this.formErrors, this.validationRules)
      if (this.formErrors.length > 0) return

      this.submitted = true
      await this.$store.dispatch('fachgebietKernkompetenzen/update', {
        id: this.kernkompetenzId,
        formData: this.formData,
      })
      this.$router.push({
        name: 'FachgebieteShow',
        params: { fachgebietId: this.fachgebiet.id },
      })
    },
    async deleteKernkompetenz() {
      await this.$store.dispatch('fachgebietKernkompetenzen/delete', this.kernkompetenzId)
      this.$router.push({
        name: 'FachgebieteShow',
        params: { fachgebietId: this.fachgebiet.id },
      })
    },
  },
}
</script>
