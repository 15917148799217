<template>
  <div>
    <GoBack :to="{ name: 'TeamIndex' }">Team</GoBack>

    <h1>Rechtsanwalt: &bdquo;{{ rechtsanwalt.name }}&ldquo;</h1>

    <div class="img">
      <ResponsiveImage :urls="rechtsanwalt.defaultBild" sizes="32rem" alt="Standard-Bild" />
    </div>

    <VSpacer mode="small" />

    <div class="img">
      <ResponsiveImage :urls="rechtsanwalt.hoverBild" sizes="32rem" alt="Hover-Bild" />
    </div>

    <VSpacer mode="small" />

    <DetailsTable>
      <tbody>
        <tr>
          <th>Name</th>
          <td>{{ rechtsanwalt.name }}</td>
        </tr>
        <tr>
          <th>Portrait</th>
          <td>{{ trimText(rechtsanwalt.portrait, 512) }}</td>
        </tr>
      </tbody>
    </DetailsTable>

    <VSpacer mode="small" />

    <VButton type="link" :to="{ name: 'RechtsanwaelteEdit', params: { rechtsanwaltId } }">
      BEARBEITEN
    </VButton>
    <VButton class="delete-button" type="button" mode="danger" @click="$refs.deleteModal.open()">
      LÖSCHEN
    </VButton>

    <VSpacer />

    <h2>Tätigkeitsschwerpunkte</h2>

    <VSpacer mode="small" />

    <ListTable v-if="schwerpunkte">
      <thead v-if="schwerpunkte.length > 0">
        <tr>
          <th>Schwerpunkt</th>
          <th><OrderIcon class="table-icon" /></th>
        </tr>
      </thead>
      <TbodyDraggable @end="onOrderEnd($event, 'schwerpunkte')">
        <tr v-for="({ id, schwerpunkt }, i) in schwerpunkte" :key="id">
          <TdLink :to="{ name: 'RechtsanwaltSchwerpunkteEdit', params: { schwerpunktId: id } }">
            {{ trimText(schwerpunkt, 48) }}
          </TdLink>
          <td>
            <TableOrder
              :order="i + 1"
              @submit="setOrder({ id, position: $event }, 'schwerpunkte')"
            />
          </td>
        </tr>
        <tr>
          <TableAddItem
            :to="{ name: 'RechtsanwaltSchwerpunkteCreate', query: { rechtsanwaltId } }"
            :colspan="2"
          >
            Schwerpunkt hinzufügen
          </TableAddItem>
        </tr>
      </TbodyDraggable>
    </ListTable>

    <VSpacer />

    <h2>Ausbildung</h2>

    <VSpacer mode="small" />

    <ListTable v-if="ausbildungen">
      <thead v-if="ausbildungen.length > 0">
        <tr>
          <th>Ausbildung</th>
          <th><OrderIcon class="table-icon" /></th>
        </tr>
      </thead>
      <TbodyDraggable @end="onOrderEnd($event, 'ausbildungen')">
        <tr v-for="({ id, ausbildung }, i) in ausbildungen" :key="id">
          <TdLink :to="{ name: 'RechtsanwaltAusbildungenEdit', params: { ausbildungId: id } }">
            {{ trimText(ausbildung, 48) }}
          </TdLink>
          <td>
            <TableOrder
              :order="i + 1"
              @submit="setOrder({ id, position: $event }, 'ausbildungen')"
            />
          </td>
        </tr>
        <tr>
          <TableAddItem
            :to="{ name: 'RechtsanwaltAusbildungenCreate', query: { rechtsanwaltId } }"
            :colspan="2"
          >
            Ausbildung hinzufügen
          </TableAddItem>
        </tr>
      </TbodyDraggable>
    </ListTable>

    <VSpacer />

    <h2>Berufserfahrung</h2>

    <VSpacer mode="small" />

    <ListTable v-if="berufserfahrungen">
      <thead v-if="berufserfahrungen.length > 0">
        <tr>
          <th>Berufserfahrung</th>
          <th><OrderIcon class="table-icon" /></th>
        </tr>
      </thead>
      <TbodyDraggable @end="onOrderEnd($event, 'berufserfahrungen')">
        <tr v-for="({ id, berufserfahrung }, i) in berufserfahrungen" :key="id">
          <TdLink
            :to="{ name: 'RechtsanwaltBerufserfahrungenEdit', params: { berufserfahrungId: id } }"
          >
            {{ trimText(berufserfahrung, 48) }}
          </TdLink>
          <td>
            <TableOrder
              :order="i + 1"
              @submit="setOrder({ id, position: $event }, 'berufserfahrungen')"
            />
          </td>
        </tr>
        <tr>
          <TableAddItem
            :to="{ name: 'RechtsanwaltBerufserfahrungenCreate', query: { rechtsanwaltId } }"
            :colspan="2"
          >
            Berufserfahrung hinzufügen
          </TableAddItem>
        </tr>
      </TbodyDraggable>
    </ListTable>

    <VSpacer />

    <h2>Funktionen</h2>

    <VSpacer mode="small" />

    <ListTable v-if="funktionen">
      <thead v-if="funktionen.length > 0">
        <tr>
          <th>Funktion</th>
          <th><OrderIcon class="table-icon" /></th>
        </tr>
      </thead>
      <TbodyDraggable @end="onOrderEnd($event, 'funktionen')">
        <tr v-for="({ id, funktion }, i) in funktionen" :key="id">
          <TdLink :to="{ name: 'RechtsanwaltFunktionenEdit', params: { funktionId: id } }">
            {{ trimText(funktion, 48) }}
          </TdLink>
          <td>
            <TableOrder :order="i + 1" @submit="setOrder({ id, position: $event }, 'funktionen')" />
          </td>
        </tr>
        <tr>
          <TableAddItem
            :to="{ name: 'RechtsanwaltFunktionenCreate', query: { rechtsanwaltId } }"
            :colspan="2"
          >
            Funktion hinzufügen
          </TableAddItem>
        </tr>
      </TbodyDraggable>
    </ListTable>

    <VSpacer />

    <h2>Publikationen</h2>

    <VSpacer mode="small" />

    <ListTable v-if="publikationen">
      <thead v-if="publikationen.length > 0">
        <tr>
          <th>Publikation</th>
          <th><OrderIcon class="table-icon" /></th>
        </tr>
      </thead>
      <TbodyDraggable @end="onOrderEnd($event, 'publikationen')">
        <tr v-for="({ id, publikation }, i) in publikationen" :key="id">
          <TdLink :to="{ name: 'RechtsanwaltPublikationenEdit', params: { publikationId: id } }">
            {{ trimText(publikation, 48) }}
          </TdLink>
          <td>
            <TableOrder
              :order="i + 1"
              @submit="setOrder({ id, position: $event }, 'publikationen')"
            />
          </td>
        </tr>
        <tr>
          <TableAddItem
            :to="{ name: 'RechtsanwaltPublikationenCreate', query: { rechtsanwaltId } }"
            :colspan="2"
          >
            Publikation hinzufügen
          </TableAddItem>
        </tr>
      </TbodyDraggable>
    </ListTable>

    <ModalConfirm ref="deleteModal" @confirm="deleteRechtsanwalt">
      Sind Sie sicher, dass Sie diesen Rechtsanwalt löschen wollen?
    </ModalConfirm>
  </div>
</template>

<script>
import store from '@/store'

import trimText from '@/assets/js/trimText'

import GoBack from '@/components/global/GoBack'
import TableComponents from '@/components/TableComponents'
import VButton from '@/components/global/Button'
import VSpacer from '@/components/global/Spacer'
import ResponsiveImage from '@/components/ResponsiveImage'
import ModalConfirm from '@/components/global/ModalConfirm'

import OrderIcon from '@/components/cmsIcons/OrderIcon'

export default {
  name: 'RechtsanwaelteShow',
  components: {
    GoBack,
    ...TableComponents,
    VButton,
    VSpacer,
    ResponsiveImage,
    OrderIcon,
    ModalConfirm,
  },
  props: ['rechtsanwaltId'],
  data() {
    return {
      orderTypeConversionTable: {
        schwerpunkte: 'rechtsanwaltSchwerpunkte',
        ausbildungen: 'rechtsanwaltAusbildungen',
        berufserfahrungen: 'rechtsanwaltBerufserfahrungen',
        funktionen: 'rechtsanwaltFunktionen',
        publikationen: 'rechtsanwaltPublikationen',
      },
    }
  },
  async beforeRouteEnter(to, from, next) {
    const rechtsanwalt = await store.dispatch('rechtsanwaelte/getOne', to.params.rechtsanwaltId)
    store.commit('rechtsanwaltAusbildungen/SET_ALL', rechtsanwalt.ausbildungen)
    store.commit('rechtsanwaltBerufserfahrungen/SET_ALL', rechtsanwalt.berufserfahrungen)
    store.commit('rechtsanwaltFunktionen/SET_ALL', rechtsanwalt.funktionen)
    store.commit('rechtsanwaltSchwerpunkte/SET_ALL', rechtsanwalt.schwerpunkte)
    store.commit('rechtsanwaltPublikationen/SET_ALL', rechtsanwalt.publikationen)
    next()
  },
  computed: {
    rechtsanwalt: () => store.getters['rechtsanwaelte/rechtsanwalt'],
    ausbildungen: () => store.getters['rechtsanwaltAusbildungen/ausbildungen'],
    berufserfahrungen: () => store.getters['rechtsanwaltBerufserfahrungen/berufserfahrungen'],
    funktionen: () => store.getters['rechtsanwaltFunktionen/funktionen'],
    schwerpunkte: () => store.getters['rechtsanwaltSchwerpunkte/schwerpunkte'],
    publikationen: () => store.getters['rechtsanwaltPublikationen/publikationen'],
  },
  methods: {
    trimText,
    setOrder(payload, type) {
      this.$store.dispatch(`${this.orderTypeConversionTable[type]}/setPosition`, payload)
    },
    onOrderEnd(event, type) {
      const id = this[type][event.oldIndex].id
      const position = event.newIndex + 1
      this.$store.dispatch(`${this.orderTypeConversionTable[type]}/setPosition`, { id, position })
    },
    async deleteRechtsanwalt() {
      await this.$store.dispatch('rechtsanwaelte/delete', this.rechtsanwaltId)
      this.$router.push({ name: 'TeamIndex' })
    },
  },
}
</script>

<style lang="scss" scoped>
.table-icon {
  height: 2rem;
  fill: $col-white;
}

.delete-button {
  margin-left: 1rem;
}

.img {
  width: 24rem;
}
</style>
