<template>
  <div>
    <GoBack :to="{ name: 'KanzleiIndex' }">Kanzlei</GoBack>

    <h1>Kanzlei bearbeiten</h1>

    <VForm @submit="submit">
      <FormBoxFields>
        <FormFieldTextarea
          field="article"
          label="Text*"
          labelWidth="8rem"
          fieldWidth="32rem"
          :rows="24"
          v-model="formData.article"
          :errors="formErrors"
          :rules="validationRules"
          :af="true"
        />

        <FormRowFields>
          <FormLabelInline for="bild" width="8rem">
            Bild
            <FormInfo title="Bild">
              Wenn Sie ein Bild auswählen, wird das bisherige Bild durch das ausgewählte Bild
              ersetzt. Bitte stellen Sie sicher, dass das Bild im sRGB-Farbprofil abgespeichert ist,
              um unerwünschte Farbveränderungen zu vermeiden. Maximal 10 MB.
            </FormInfo>
          </FormLabelInline>
          <input type="file" name="bild" ref="bild" />
        </FormRowFields>
      </FormBoxFields>

      <FormRowButtons>
        <VButton type="submit" :deactivated="submitted">SPEICHERN</VButton>
        <VButton type="link" :to="{ name: 'KanzleiIndex' }" mode="faint">ABBRECHEN</VButton>
      </FormRowButtons>
    </VForm>

    <VModal ref="imageOptimizationsModal">
      <template slot="title">Bitte warten...</template>
      <p>
        Das Bild wird für unterschiedliche Bildschirmgrößen optimiert. Dieser Prozess kann mehrere
        Minuten dauern.
      </p>
    </VModal>
  </div>
</template>

<script>
import store from '@/store'
import formValidation from '@/assets/js/formValidation'
import validationRules from '@/assets/js/validationRules/kanzlei'

import GoBack from '@/components/global/GoBack'
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/global/Button'
import VModal from '@/components/global/Modal'

export default {
  name: 'KanzleiEdit',
  components: {
    GoBack,
    ...FormComponents,
    VButton,
    VModal,
  },
  data() {
    return {
      formData: null,
      formErrors: [],
      validationRules,
      submitted: false,
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('kanzlei/get')
    next()
  },
  created() {
    this.formData = {
      article: this.kanzlei.article ?? '',
    }
  },
  computed: {
    kanzlei: () => store.getters['kanzlei/kanzlei'],
  },
  methods: {
    ...formValidation,
    async submit() {
      this.validateAll(this.formData, this.formErrors, this.validationRules)
      if (this.formErrors.length > 0) return

      this.submitted = true
      let formData = new FormData()
      Object.entries(this.formData).forEach(([field, value]) => {
        formData.append(field, value)
      })
      if (this.$refs.bild.files?.[0]) {
        formData.append('bild', this.$refs.bild.files[0])
        this.$refs.imageOptimizationsModal.open()
      }

      await this.$store.dispatch('kanzlei/update', formData)
      this.$router.push({ name: 'KanzleiIndex' })
    },
  },
}
</script>
