<template>
  <div>
    <GoBack :to="{ name: 'TeamIndex' }">Team</GoBack>

    <h1>Teammitglied: &bdquo;{{ teammitglied.name }}&ldquo;</h1>

    <DetailsTable>
      <tbody>
        <tr>
          <th>Name</th>
          <td>{{ teammitglied.name }}</td>
        </tr>
        <tr>
          <th>Rolle</th>
          <td>{{ teammitglied.role }}</td>
        </tr>
        <tr>
          <th>Portrait</th>
          <td>{{ teammitglied.portrait ? trimText(teammitglied.portrait, 512) : '' }}</td>
        </tr>
      </tbody>
    </DetailsTable>

    <VSpacer mode="small" />

    <VButton type="link" :to="{ name: 'TeammitgliederEdit', params: { teammitgliedId } }">
      BEARBEITEN
    </VButton>
    <VButton class="delete-button" type="button" mode="danger" @click="$refs.deleteModal.open()">
      LÖSCHEN
    </VButton>

    <ModalConfirm ref="deleteModal" @confirm="deleteTeammitglied">
      Sind Sie sicher, dass Sie dieses Teammitglied löschen wollen?
    </ModalConfirm>
  </div>
</template>

<script>
import store from '@/store'

import trimText from '@/assets/js/trimText'

import GoBack from '@/components/global/GoBack'
import TableComponents from '@/components/TableComponents'
import VButton from '@/components/global/Button'
import VSpacer from '@/components/global/Spacer'
import ModalConfirm from '@/components/global/ModalConfirm'

export default {
  name: 'RechtsanwaelteShow',
  components: {
    GoBack,
    ...TableComponents,
    VButton,
    VSpacer,
    ModalConfirm,
  },
  props: ['teammitgliedId'],
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('teammitglieder/getOne', to.params.teammitgliedId)
    next()
  },
  computed: {
    teammitglied: () => store.getters['teammitglieder/teammitglied'],
  },
  methods: {
    trimText,
    async deleteTeammitglied() {
      await this.$store.dispatch('teammitglieder/delete', this.teammitgliedId)
      this.$router.push({ name: 'TeamIndex' })
    },
  },
}
</script>

<style lang="scss" scoped>
.table-icon {
  height: 2rem;
  fill: $col-white;
}

.delete-button {
  margin-left: 1rem;
}
</style>
