import MenuIcons from './Icons'

export default [
  {
    title: 'Home',
    route: 'HomeIndex',
    icon: MenuIcons.MenuPageIcon,
  },
  {
    title: 'Kanzlei',
    route: 'KanzleiIndex',
    icon: MenuIcons.MenuPageIcon,
  },
  {
    title: 'Fachgebiete',
    route: 'FachgebieteIndex',
    icon: MenuIcons.MenuPageIcon,
  },
  {
    title: 'Team',
    route: 'TeamIndex',
    icon: MenuIcons.MenuPageIcon,
  },
  {
    title: 'Honorar',
    route: 'HonorarIndex',
    icon: MenuIcons.MenuPageIcon,
  },
  {
    title: 'Datenschutz',
    route: 'DatenschutzIndex',
    icon: MenuIcons.MenuPageIcon,
  },
  {
    title: 'Anleitung / Hilfe',
    route: 'Dashboard',
    icon: MenuIcons.MenuHelpIcon,
    exact: true,
  },
  {
    title: 'Passwort ändern',
    route: 'PasswortAendern',
    icon: MenuIcons.MenuPasswordIcon,
  },
]
