<template>
  <div>
    <GoBack :to="{ name: 'RechtsanwaelteShow', params: { rechtsanwaltId } }">
      Rechtsanwalt: &bdquo;{{ rechtsanwalt.name }}&ldquo;
    </GoBack>

    <h1>Funktion anlegen</h1>

    <VForm @submit="submit">
      <FormBoxFields>
        <FormFieldInput
          field="funktion"
          label="Funktion*"
          fieldWidth="32rem"
          v-model="formData.funktion"
          :errors="formErrors"
          :rules="validationRules"
          :af="true"
        />
      </FormBoxFields>

      <FormRowButtons>
        <VButton type="submit">SPEICHERN</VButton>
        <VButton
          type="link"
          :to="{ name: 'RechtsanwaelteShow', params: { rechtsanwaltId } }"
          mode="faint"
        >
          ABBRECHEN
        </VButton>
      </FormRowButtons>
    </VForm>
  </div>
</template>

<script>
import store from '@/store'
import formValidation from '@/assets/js/formValidation'
import validationRules from '@/assets/js/validationRules/rechtsanwaltFunktionen'

import GoBack from '@/components/global/GoBack'
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/global/Button'

export default {
  name: 'RechtsanwaltFunktionenCreate',
  components: {
    GoBack,
    ...FormComponents,
    VButton,
  },
  props: ['rechtsanwaltId'],
  data() {
    return {
      formData: {
        funktion: '',
      },
      formErrors: [],
      validationRules,
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('rechtsanwaelte/getOne', to.query.rechtsanwaltId)
    next()
  },
  computed: {
    rechtsanwalt: () => store.getters['rechtsanwaelte/rechtsanwalt'],
  },
  methods: {
    ...formValidation,
    async submit() {
      this.validateAll(this.formData, this.formErrors, this.validationRules)
      if (this.formErrors.length > 0) return

      await this.$store.dispatch('rechtsanwaltFunktionen/create', {
        ...this.formData,
        rechtsanwalt_id: this.rechtsanwaltId,
      })
      this.$router.push({
        name: 'RechtsanwaelteShow',
        params: { rechtsanwaltId: this.rechtsanwaltId },
      })
    },
  },
}
</script>
