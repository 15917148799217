<template>
  <div>
    <GoBack :to="{ name: 'RechtsanwaelteShow', params: { rechtsanwaltId } }">
      Rechtsanwalt: &bdquo;{{ rechtsanwalt.name }}&ldquo;
    </GoBack>

    <h1>Rechtsanwalt bearbeiten</h1>

    <VForm @submit="submit">
      <FormBoxFields>
        <FormFieldInput
          field="name"
          label="Name*"
          fieldWidth="32rem"
          v-model="formData.name"
          :errors="formErrors"
          :rules="validationRules"
          :af="true"
        />

        <FormFieldInput
          field="role"
          label="Rolle*"
          fieldWidth="32rem"
          v-model="formData.role"
          :errors="formErrors"
          :rules="validationRules"
        />

        <FormFieldTextarea
          field="portrait"
          label="Portrait*"
          fieldWidth="32rem"
          :rows="12"
          v-model="formData.portrait"
          :errors="formErrors"
          :rules="validationRules"
        />

        <FormRowFields>
          <FormLabelInline for="defaultBild">
            Neues Standard-Bild
            <FormInfo title="Standard-Bild">
              Das reguläre Portrait-Foto. Wenn Sie ein Bild auswählen, wird das bisherige Bild durch
              das ausgewählte Bild ersetzt. Bitte stellen Sie sicher, dass das Bild im
              sRGB-Farbprofil abgespeichert ist, um unerwünschte Farbveränderungen zu vermeiden.
              Maximal 10 MB.
            </FormInfo>
          </FormLabelInline>
          <input type="file" name="defaultBild" ref="defaultBild" />
        </FormRowFields>

        <FormRowFields>
          <FormLabelInline for="hoverBild">
            Neues Hover-Bild
            <FormInfo title="Hover-Bild">
              Jenes Foto, das man sieht, wenn man mit der Maus über das reguläre Portrait-Foto
              streicht. Wenn Sie ein Bild auswählen, wird das bisherige Bild durch das ausgewählte
              Bild ersetzt. Bitte stellen Sie sicher, dass das Bild im sRGB-Farbprofil abgespeichert
              ist, um unerwünschte Farbveränderungen zu vermeiden. Maximal 10 MB.
            </FormInfo>
          </FormLabelInline>
          <input type="file" name="hoverBild" ref="hoverBild" />
        </FormRowFields>
      </FormBoxFields>

      <FormRowButtons>
        <VButton type="submit" :deactivated="submitted">SPEICHERN</VButton>
        <VButton
          type="link"
          :to="{ name: 'RechtsanwaelteShow', params: { rechtsanwaltId } }"
          mode="faint"
        >
          ABBRECHEN
        </VButton>
      </FormRowButtons>
    </VForm>

    <VModal ref="imageOptimizationsModal">
      <template slot="title">Bitte warten...</template>
      <p>
        Die Bilder werden für unterschiedliche Bildschirmgrößen optimiert. Dieser Prozess kann
        mehrere Minuten dauern.
      </p>
    </VModal>
  </div>
</template>

<script>
import store from '@/store'
import formValidation from '@/assets/js/formValidation'
import validationRules from '@/assets/js/validationRules/rechtsanwaelte'

import GoBack from '@/components/global/GoBack'
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/global/Button'
import VModal from '@/components/global/Modal'

export default {
  name: 'RechtsanwaelteEdit',
  components: {
    GoBack,
    ...FormComponents,
    VButton,
    VModal,
  },
  props: ['rechtsanwaltId'],
  data() {
    return {
      formData: null,
      formErrors: [],
      validationRules,
      submitted: false,
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('rechtsanwaelte/getOne', to.params.rechtsanwaltId)
    next()
  },
  created() {
    this.formData = {
      name: this.rechtsanwalt.name ?? '',
      role: this.rechtsanwalt.role ?? '',
      portrait: this.rechtsanwalt.portrait ?? '',
    }
  },
  computed: {
    rechtsanwalt: () => store.getters['rechtsanwaelte/rechtsanwalt'],
  },
  methods: {
    ...formValidation,
    async submit() {
      this.validateAll(this.formData, this.formErrors, this.validationRules)
      if (this.formErrors.length > 0) return

      this.submitted = true
      let formData = new FormData()
      Object.entries(this.formData).forEach(([field, value]) => {
        formData.append(field, value)
      })
      if (this.$refs.defaultBild.files?.[0] || this.$refs.hoverBild.files?.[0]) {
        this.$refs.imageOptimizationsModal.open()
      }
      if (this.$refs.defaultBild.files?.[0]) {
        formData.append('default_bild', this.$refs.defaultBild.files[0])
      }
      if (this.$refs.hoverBild.files?.[0]) {
        formData.append('hover_bild', this.$refs.hoverBild.files[0])
      }

      await this.$store.dispatch('rechtsanwaelte/update', {
        id: this.rechtsanwaltId,
        formData,
      })
      this.$router.push({
        name: 'RechtsanwaelteShow',
        params: { rechtsanwaltId: this.rechtsanwaltId },
      })
    },
  },
}
</script>
