<template>
  <div>
    <GoBack :to="{ name: 'FachgebieteShow', params: { fachgebietId } }">
      Fachgebiet: &bdquo;{{ fachgebiet.title }}&ldquo;
    </GoBack>

    <h1>Kernkompetenz anlegen</h1>

    <VForm @submit="submit">
      <FormBoxFields>
        <FormFieldTextarea
          field="kompetenz"
          label="Kernkompetenz*"
          fieldWidth="32rem"
          :rows="4"
          v-model="formData.kompetenz"
          :errors="formErrors"
          :rules="validationRules"
          :af="true"
        />
      </FormBoxFields>

      <FormRowButtons>
        <VButton type="submit">SPEICHERN</VButton>
        <VButton
          type="link"
          :to="{ name: 'FachgebieteShow', params: { fachgebietId } }"
          mode="faint"
        >
          ABBRECHEN
        </VButton>
      </FormRowButtons>
    </VForm>
  </div>
</template>

<script>
import store from '@/store'
import formValidation from '@/assets/js/formValidation'
import validationRules from '@/assets/js/validationRules/fachgebietKernkompetenzen'

import GoBack from '@/components/global/GoBack'
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/global/Button'

export default {
  name: 'FachgebietKernkompetenzenCreate',
  components: {
    GoBack,
    ...FormComponents,
    VButton,
  },
  props: ['fachgebietId'],
  data() {
    return {
      formData: {
        kompetenz: '',
      },
      formErrors: [],
      validationRules,
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('fachgebiete/getOne', to.query.fachgebietId)
    next()
  },
  computed: {
    fachgebiet: () => store.getters['fachgebiete/fachgebiet'],
  },
  methods: {
    ...formValidation,
    async submit() {
      this.validateAll(this.formData, this.formErrors, this.validationRules)
      if (this.formErrors.length > 0) return

      await this.$store.dispatch('fachgebietKernkompetenzen/create', {
        ...this.formData,
        fachgebiet_id: this.fachgebietId,
      })
      this.$router.push({
        name: 'FachgebieteShow',
        params: { fachgebietId: this.fachgebietId },
      })
    },
  },
}
</script>
